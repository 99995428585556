import React, { Component } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
class AdminUserSearch extends Component {
  state = {
    allUsers: [],
    filteredUsers: [],
    userString: '',
    confirm: false,
    duration: '',
    selectedId: null,
  };

  componentDidMount() {
    axios.get('/api/admin/user-list/free').then((res) => this.setState({ allUsers: res.data }));
  }

  handleChange(searchString) {
    this.setState({ userString: searchString });

    const allUsers = this.state.allUsers;

    const filteredUsers = allUsers.filter(
      (user) =>
        (user.email && user.email.toLowerCase().includes(searchString)) ||
        (user.first_name && user.first_name.toLowerCase().includes(searchString)),
    );

    this.setState({ filteredUsers });
  }

  handleConfirm() {
    axios.put('/api/admin/user-access', {
      duration: this.state.duration,
      userId: this.state.selectedId,
    });

    this.setState({ confirm: false });
  }

  handleButton() {
    this.setState({ confirm: true });
  }

  setChoice(duration, userId) {
    if (this.state.confirm) {
      return;
    }

    this.setState({ duration, selectedId: userId });
  }

  render() {
    const allUsers = this.state.allUsers.map((el, i) => (
      <div key={i} className="user-item">
        <span>{el.first_name}</span>
        <span>{el.email}</span>
        <div>
          <button
            onClick={() => {
              this.setChoice('month', el.id);
              this.handleButton();
            }}
          >
            Give Free Month
          </button>
          <button
            onClick={() => {
              this.setChoice('year', el.id);
              this.handleButton();
            }}
          >
            Give Free Year
          </button>
        </div>
      </div>
    ));
    const filteredUsers = this.state.filteredUsers.map((el, i) => (
      <div key={i} className="user-item">
        <span>{el.first_name}</span>
        <span>{el.email}</span>
        <div>
          <button
            onClick={() => {
              this.setChoice('month', el.id);
              this.handleButton();
            }}
          >
            Give Free Month
          </button>
          <button
            onClick={() => {
              this.setChoice('year', el.id);
              this.handleButton();
            }}
          >
            Give Free Year
          </button>
        </div>
      </div>
    ));
    return (
      <div className="admin-user-search-container">
        <div className="top">
          <h3>Give a User Free Access</h3>
          <input
            value={this.state.userString}
            onChange={(e) => this.handleChange(e.target.value.toLowerCase())}
            placeholder="Search by Email Address or First Name"
            type="text"
          />
        </div>
        <div className="bottom">{this.state.userString ? filteredUsers : allUsers}</div>
        {this.state.confirm ? (
          <div className="confirm">
            <span>Are you sure?</span>
            <div className="buttons">
              <button onClick={() => this.handleConfirm()}>Confirm</button>
              <button onClick={() => this.setState({ confirm: false })}>cancel</button>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  }
}

export default withRouter(AdminUserSearch);
