import React, { Component } from 'react';
import axios from 'axios';
import { BounceLoader } from 'react-spinners';
import { css } from '@emotion/core';
import { withRouter } from 'react-router-dom';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

class AdminJourney extends Component {
  state = {
    journeyTitle: '',
    subtitle: '',
    comingSoon: false,
    selectedImageFile: null,
    loading: false,
  };

  handleChange(value, type) {
    if (type === 'title') {
      this.setState({ journeyTitle: value });
    } else if (type === 'subtitle') {
      this.setState({ subtitle: value });
    }
  }

  handleFile(file) {
    this.setState({ selectedImageFile: file });
  }

  handleSubmit() {
    if (!this.state.journeyTitle || !this.state.subtitle || !this.state.selectedImageFile) {
      return window.alert('Please fill everything out');
    }

    const submitButton = document.getElementById('submit');
    submitButton.disabled = true;

    this.setState({ loading: true });

    const formData = new FormData();

    formData.append('image_file', this.state.selectedImageFile, this.state.selectedImageFile.name);
    formData.append('journey_title', this.state.journeyTitle);
    formData.append('subtitle', this.state.subtitle);
    formData.append('coming_soon', this.state.comingSoon);

    axios
      .post('/api/admin/journey', formData)
      .then((res) => {
        if (res.status === 200) {
          window.alert('Journey added successfully!');
          this.setState(
            {
              journeyTitle: '',
              subtitle: '',
              comingSoon: false,
              selectedImageFile: null,
              loading: false,
            },
            () => {
              document.getElementById('image-file').value = null;
              submitButton.disabled = false;
            },
          );
        } else {
          window.alert('unknown error');
          submitButton.disabled = false;
          this.setState({ loading: false });
        }
      })
      .catch((err) => this.props.history.push('/home'));
  }

  render() {
    return (
      <div className="admin-journey-container">
        <h3>Add Journey</h3>
        <span>Journey (category) title: </span>
        <input
          onChange={(e) => this.handleChange(e.target.value, 'title')}
          placeholder="e.g. fire"
          type="text"
          value={this.state.journeyTitle}
        />
        <span>Journey (category) subtitle: </span>
        <input
          onChange={(e) => this.handleChange(e.target.value, 'subtitle')}
          placeholder="Keep it short"
          type="text"
          value={this.state.subtitle}
        />

        <div className="upload">
          <span>Upload Image</span>
          <input id="image-file" onChange={(e) => this.handleFile(e.target.files[0])} type="file" />
        </div>

        <div className="coming-soon-container">
          <span>Is this coming soon? (unlikely)</span>
          <button
            className={this.state.comingSoon ? 'enabled coming-soon-button' : 'coming-soon-button'}
            onClick={() => this.setState({ comingSoon: true })}
          >
            yes
          </button>
          <button
            className={this.state.comingSoon ? 'coming-soon-button' : 'enabled coming-soon-button'}
            onClick={() => this.setState({ comingSoon: false })}
          >
            no
          </button>
        </div>
        <button onClick={() => this.handleSubmit()} id="submit" className="submit">
          Submit
        </button>
        {this.state.loading ? (
          <div className="loading-results-admin">
            <BounceLoader
              color={'#a89cd9'}
              css={override}
              sizeUnit={'px'}
              size={75}
              loading={this.state.loading}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  }
}

export default withRouter(AdminJourney);
