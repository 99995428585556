import React, { useState } from 'react';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { BounceLoader } from 'react-spinners';
import { css } from '@emotion/core';

import * as _ from 'lodash';

import axios from 'axios';

const stripePromise = loadStripe('pk_live_bUOKTLhFsLYqBeOIK7Ag2A2v004GiKe7uS');

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

let validCoupon;

const createPaymentMethod = async ({ stripe, cardElement, priceId, props, setLoading }) => {
  return stripe
    .createPaymentMethod({
      type: 'card',
      card: cardElement,
    })
    .then((result) => {
      if (result.error) {
        showCardError(result.error, setLoading);
      } else {
        return createSubscription({
          paymentMethodId: result.paymentMethod.id,
          priceId,
          props,
          stripe,
          setLoading,
        });
      }
    });
};

const createSubscription = ({ paymentMethodId, priceId, props, stripe, setLoading }) => {
  return (
    axios
      .post('/api/stripe/subscription', {
        paymentMethodId: paymentMethodId,
        priceId: priceId,
        coupon: validCoupon ? validCoupon.data.stripe_coupon_id : undefined,
      })
      // If the card is declined, display an error to the user.
      .then((result) => {
        if (result.error) {
          // The card had an error when trying to attach it to a customer.
          throw result;
        }
        return result;
      })
      // Normalize the result to contain the object returned by Stripe.
      // Add the addional details we need.
      .then((result) => {
        return {
          paymentMethodId: paymentMethodId,
          priceId: priceId,
          subscription: result.data,
          stripe,
        };
      })
      // Some payment methods require a customer to be on session
      // to complete the payment process. Check the status of the
      // payment intent to handle these actions.
      .then(handlePaymentThatRequiresCustomerAction)
      // If attaching this card to a Customer object succeeds,
      // but attempts to charge the customer fail, you
      // get a requires_payment_method error.
      //   .then(handleRequiresPaymentMethod)
      // No more actions required. Provision your service for the user.
      .then((result) => onSubscriptionComplete(result, props))
      .catch((error) => {
        // An error has happened. Display the failure to the user here.
        // We utilize the HTML element we created.
        showCardError(error, setLoading);
      })
  );
};

const onSubscriptionComplete = async (result, props) => {
  await axios.put('/api/stripe/confirm-subscription', { couponId: _.get(validCoupon, 'data.id') });
  props.history.push('/');
};

const showCardError = (errorRes, setLoading) => {
  setLoading(false);

  document.getElementById('confirm-sub').disabled = false;

  const displayError = document.getElementById('card-errors');
  const errorMessage = _.get(errorRes, 'response.data.error.message');

  if (errorMessage) {
    displayError.textContent = `* ${errorMessage}`;
  } else if (_.get(errorRes, 'error.code') === 'payment_intent_authentication_failure') {
    displayError.textContent = 'We are unable to authenticate your payment method.';
  } else {
    displayError.textContent = '* Unknown error occurred. Try again.';
  }
};

const handlePaymentThatRequiresCustomerAction = ({
  subscription,
  invoice,
  priceId,
  paymentMethodId,
  isRetry,
  stripe,
}) => {
  if (subscription && subscription.status === 'active') {
    // Subscription is active, no customer actions required.
    return { subscription, priceId, paymentMethodId };
  }

  // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
  // If it's a retry, the payment intent will be on the invoice itself.
  let paymentIntent = invoice ? invoice.payment_intent : subscription.latest_invoice.payment_intent;

  if (
    paymentIntent.status === 'requires_action' ||
    (isRetry === true && paymentIntent.status === 'requires_payment_method')
  ) {
    return stripe
      .confirmCardPayment(paymentIntent.client_secret, {
        payment_method: paymentMethodId,
      })
      .then((result) => {
        if (result.error) {
          // Start code flow to handle updating the payment details.
          // Display error message in your UI.
          // The card was declined (i.e. insufficient funds, card has expired, etc).
          throw result;
        } else {
          if (result.paymentIntent.status === 'succeeded') {
            // Show a success message to your customer.
            // There's a risk of the customer closing the window before the callback.
            // We recommend setting up webhook endpoints later in this guide.
            return {
              priceId: priceId,
              subscription: subscription,
              invoice: invoice,
              paymentMethodId: paymentMethodId,
            };
          }
        }
      });
  } else {
    // No customer action needed.
    return { subscription, priceId, paymentMethodId };
  }
};

const handleButton = async ({ stripe, elements, props, setLoading }) => {
  document.getElementById('confirm-sub').disabled = true;

  setLoading(true);

  const displayError = document.getElementById('card-errors');
  displayError.textContent = '';

  const cardElement = elements.getElement(CardElement);

  const priceIds = await axios.get('/api/stripe/price-ids');

  let priceId;
  if (props.location.state.plan === 'monthly') {
    priceId = priceIds.data.monthly;
  } else if (props.location.state.plan === 'yearly') {
    priceId = priceIds.data.yearly;
  }

  createPaymentMethod({ stripe, cardElement, priceId, props, setLoading });
};

const Payment = (props) => {
  props.handleNav(false);

  return (
    <Elements stripe={stripePromise}>
      <PaymentInner {...props} />
    </Elements>
  );
};

const PaymentInner = (props) => {
  const stripe = useStripe();
  const elements = useElements();

  const [loading, setLoading] = useState(false);
  const [couponText, setCoupon] = useState('');
  const [couponRedeemed, setRedeem] = useState(false);
  const [couponRejected, setReject] = useState(false);
  const [discountedPrice, setDiscount] = useState('');

  const handleRedeem = async () => {
    if (!couponText) {
      return;
    }

    validCoupon = await axios.post('/api/stripe/coupon-code', {
      couponCode: couponText,
      plan: props.location.state.plan,
    });

    if (validCoupon.data === 'not found') {
      setRedeem(false);
      setReject(true);
    } else if (validCoupon.data.stripe_coupon_id && validCoupon.data.product_price) {
      setDiscount(validCoupon.data.product_price);
      setRedeem(true);
    } else {
      setRedeem(false);
      console.log('an error occurred.');
    }
  };

  return (
    <div className="payment-container">
      <div className="payment-inner">
        <label>
          {props.location.state.plan === 'yearly' ? (
            couponRedeemed ? (
              <div>
                <span>Annual Subscription </span>
                <span style={{ textDecoration: 'line-through', color: 'red' }}>$78.95 </span>
                <span style={{ color: 'green', marginLeft: '5px' }}>{`$${discountedPrice}`}</span>
              </div>
            ) : couponRejected ? (
              <div>
                <span>Annual Subscription </span>
                <span>$78.95 </span>
                <span style={{ color: 'red', marginLeft: '5px' }}>Invalid Coupon</span>
              </div>
            ) : (
              <div>
                <span>Annual Subscription $78.95 </span>
              </div>
            )
          ) : couponRedeemed ? (
            <div>
              <span>Monthly Subscription </span>
              <span style={{ textDecoration: 'line-through', color: 'red' }}>$7.95 </span>
              <span style={{ color: 'green', marginLeft: '5px' }}>{`$${discountedPrice}`}</span>
            </div>
          ) : couponRejected ? (
            <div>
              <span>Monthly Subscription </span>
              <span>$7.95 </span>
              <span style={{ color: 'red', marginLeft: '5px' }}>Invalid Coupon</span>
            </div>
          ) : (
            <div>
              <span>Monthly Subscription $7.95 </span>
            </div>
          )}
        </label>
        <br />
        <div className="coupon-container">
          <input
            placeholder="Coupon Code"
            className="coupon-input"
            onChange={(e) => setCoupon(e.target.value)}
            type="text"
          />
          <button className="confirm-sub redeem" onClick={() => handleRedeem()}>
            Redeem
          </button>
        </div>
        <label>
          <CardElement />
          <div id="card-errors" role="alert"></div>
        </label>
        <div className="sub-buttons">
          <button
            id="confirm-sub"
            className="confirm-sub"
            onClick={() => handleButton({ stripe, elements, props, setLoading })}
          >
            Subscribe
          </button>
          <button className="cancel-sub" onClick={() => props.history.goBack()}>
            Cancel
          </button>
        </div>
        {loading ? (
          <div className="loading-results-payment">
            <BounceLoader
              color={'#a89cd9'}
              css={override}
              sizeUnit={'px'}
              size={75}
              loading={loading}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default Payment;
