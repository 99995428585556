import React, { useState } from 'react';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { BounceLoader } from 'react-spinners';
import { css } from '@emotion/core';

import * as _ from 'lodash';

import axios from 'axios';

const stripePromise = loadStripe('pk_live_bUOKTLhFsLYqBeOIK7Ag2A2v004GiKe7uS');

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const createPaymentMethod =   async ({ stripe, cardElement, props, setLoading }) => {
  return stripe
    .createPaymentMethod({
      type: 'card',
      card: cardElement,
    })
    .then((result) => {
      if (result.error) {
        showCardError(result.error, setLoading);
      } else {
        return updatePaymentMethod({ paymentMethodId: result.paymentMethod.id, props, setLoading });
      }
    });
};

const updatePaymentMethod = ({ paymentMethodId, props, setLoading }) => {
  return (
    axios
      .put('/api/stripe/payment', {
        paymentMethodId: paymentMethodId,
      })
      // If the card is declined, display an error to the user.
      .then((result) => {
        if (result.error) {
          // The card had an error when trying to attach it to a customer.
          throw result;
        }
        return result;
      })
      // Normalize the result to contain the object returned by Stripe.
      // Add the addional details we need.
      .then((result) => {
        return {
          paymentMethodId: paymentMethodId,
          updatedPaymentMethod: result,
        };
      })
      // No more actions required. Provision your service for the user.
      .then((result) => onUpdateComplete(result, props))
      .catch((error) => {
        showCardError(error, setLoading);
        // An error has happened. Display the failure to the user here.
        // We utilize the HTML element we created.
      })
  );
};

const onUpdateComplete = (result, props) => {
  props.history.push('/');
};

const showCardError = (errorRes, setLoading) => {
  setLoading(false);

  document.getElementById('confirm-sub').disabled = false;

  const displayError = document.getElementById('card-errors');
  const errorMessage = _.get(errorRes, 'response.data.error.message');

  if (errorMessage) {
    displayError.textContent = `* ${errorMessage}`;
  } else {
    displayError.textContent = '* Unknown error occurred. Try again.';
  }
};

const handleButton = ({ stripe, elements, props, setLoading }) => {
  document.getElementById('confirm-sub').disabled = true;

  setLoading(true);

  const displayError = document.getElementById('card-errors');
  displayError.textContent = '';

  const cardElement = elements.getElement(CardElement);

  createPaymentMethod({ stripe, cardElement, props, setLoading });
};

const UpdatePayment = (props) => {
  props.handleNav(false);
  return (
    <Elements stripe={stripePromise}>
      <PaymentInner {...props} />
    </Elements>
  );
};

const PaymentInner = (props) => {
  const stripe = useStripe();
  const elements = useElements();

  const [loading, setLoading] = useState(false);

  return (
    <div className="payment-container">
      <div className="payment-inner">
        <label>
          <span>Update Payment Method</span>
        </label>
        <br />
        <label>
          <CardElement />
          <div id="card-errors" role="alert"></div>
        </label>
        <div className="sub-buttons">
          <button
            id="confirm-sub"
            className="confirm-sub"
            onClick={() => handleButton({ stripe, elements, props, setLoading })}
          >
            Update
          </button>
          <button className="cancel-sub" onClick={() => props.history.goBack()}>
            Cancel
          </button>
        </div>
        {loading ? (
          <div className="loading-results-payment">
            <BounceLoader
              color={'#a89cd9'}
              css={override}
              sizeUnit={'px'}
              size={75}
              loading={loading}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default UpdatePayment;
