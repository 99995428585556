import React from 'react';

import ImageFadeIn from 'react-image-fade-in';

function BlogPost(props) {
  return (
    <div onClick={() => window.open(`${props.data.url}`)} className="blog-post-container">
      <ImageFadeIn src={props.data.image_url} alt="blog" />
      <div className="blog-text">
        <h3>{props.data.title}</h3>
        <p>{props.data.description}</p>
      </div>
    </div>
  );
}

export default BlogPost;
