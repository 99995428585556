import React, { Component } from 'react';
import axios from 'axios';
import { BounceLoader } from 'react-spinners';
import { css } from '@emotion/core';
import { withRouter } from 'react-router-dom';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

class AdminPublish extends Component {
  state = {
    tracks: [],
    trackTitle: '',
    trackId: null,
    publish: null,
    loading: false,
  };

  handleSubmit() {
    if (!this.state.trackId || (!this.state.trackTitle && this.state.publish !== null)) {
      return window.alert('Please choose a track to manage');
    }

    const submitButton = document.getElementById('submit');
    submitButton.disabled = true;

    this.setState({ loading: true });

    axios
      .put('/api/admin/publish', {
        trackId: this.state.trackId,
        publish: this.state.publish,
      })
      .then((res) => {
        if (res.status === 200) {
          if (this.state.publish) {
            window.alert('track published successfully!');
          } else {
            window.alert('track unpublished successfully!');
          }
          this.setState(
            {
              trackTitle: '',
              trackId: null,
              publish: null,
              loading: false,
            },
            () => {
              // submitButton.disabled = false;
              this.getTracks();
            },
          );
        } else {
          window.alert('unknown error');
          // submitButton.disabled = false;
          this.setState({ loading: false });
        }
      })
      .catch((err) => this.props.history.push('/home'));
  }

  componentDidMount() {
    this.getTracks();

    if (this.state.publish === null) {
      document.getElementById('submit').disabled = true;
    }
  }

  getTracks() {
    axios.get('/api/admin/tracks').then((res) => this.setState({ tracks: res.data }));
  }

  render() {
    const tracks = this.state.tracks.map((el, i) => (
      <div
        className={el.published ? 'published' : 'unpublished'}
        onClick={() =>
          el.published
            ? this.setState(
                { trackTitle: el.title, trackId: el.id, publish: false },
                () => (document.getElementById('submit').disabled = false),
              )
            : this.setState(
                { trackTitle: el.title, trackId: el.id, publish: true },
                () => (document.getElementById('submit').disabled = false),
              )
        }
        key={i}
      >
        {el.title}
      </div>
    ));
    return (
      <div className="admin-publish-container">
        <h3>Main Track Publishing: </h3>
        <h4>***Green tracks are currently published and red tracks are not***</h4>
        <div className="tracks">
          {this.state.publish ? (
            <span>Track to publish: </span>
          ) : this.state.publish === null ? (
            'Choose 1 track to manage'
          ) : (
            <span>Track to unpublish: </span>
          )}
          <span className="track-to-publish">{this.state.trackTitle}</span>
          <div className="tracks-container">{tracks}</div>
        </div>

        <button id="submit" onClick={() => this.handleSubmit()} className="submit">
          {this.state.publish
            ? `Publish ${this.state.trackTitle}`
            : this.state.publish === null
            ? 'Choose Track'
            : `Unpublish ${this.state.trackTitle}`}
        </button>
        {this.state.loading ? (
          <div className="loading-results-admin">
            <BounceLoader
              color={'#a89cd9'}
              css={override}
              sizeUnit={'px'}
              size={75}
              loading={this.state.loading}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  }
}

export default withRouter(AdminPublish);
