import React, { Component } from 'react';
import axios from 'axios';
import { BounceLoader } from 'react-spinners';
import { css } from '@emotion/core';
import { withRouter } from 'react-router-dom';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

class AdminTag extends Component {
  state = {
    title: '',
    loading: false,
  };

  handleSubmit() {
    if (this.state.title) {
      const submitButton = document.getElementById('submit-button');
      submitButton.disabled = true;

      this.setState({ loading: true });

      axios
        .post(`/api/admin/tag`, { tag: this.state.title })
        .then((res) => {
          if (res.status === 200) {
            window.alert('tag added!');
            submitButton.disabled = false;
            this.setState({ loading: false, title: '' });
          } else {
            window.alert('unknown error');
            submitButton.disabled = false;
            this.setState({ loading: false });
          }
        })
        .catch((err) => this.props.history.push('/home'));
    } else {
      window.alert('enter a title');
    }
  }

  render() {
    return (
      <div className="admin-tag-container">
        <h3>Add Tag</h3>
        <span>Tag Title: </span>
        <input
          value={this.state.title}
          onChange={(e) => this.setState({ title: e.target.value })}
          placeholder="e.g. healing"
          type="text"
        />
        <button id="submit-button" onClick={() => this.handleSubmit()} className="submit">
          Submit
        </button>
        {this.state.loading ? (
          <div className="loading-results-admin">
            <BounceLoader
              color={'#a89cd9'}
              css={override}
              sizeUnit={'px'}
              size={75}
              loading={this.state.loading}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  }
}

export default withRouter(AdminTag);
