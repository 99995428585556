import React from 'react';

import androidTutorial1 from '../../assets/landing/tutorial1.jpg';
import androidTutorial2 from '../../assets/landing/tutorial2.jpg';
import iosTutorial1 from '../../assets/landing/tutorial3.png';
import iosTutorial2 from '../../assets/landing/tutorial4.png';
import dismiss from '../../assets/you/x.svg';
import { isIos } from '../../common/validation';

const AddToHomescreenModal = (props) => {

  return (
    <div className="dark-bg">
      <div
        className={
          props.type === 'settings'
            ? 'add-to-homescreen-modal add-to-homescreen-settings'
            : 'add-to-homescreen-modal'
        }
      >
        <img
          className="dismiss"
          onClick={props.handleAddToHomescreenModal}
          src={dismiss}
          alt="dismiss"
        />
        {isIos() ? (
          <>
            <div>1. In Safari, tap the share button at the bottom.</div>
            <img src={iosTutorial1} alt="android-tutorial1" />
            <br />
            <div>2. Tap 'Add to homescreen'.</div>
            <img src={iosTutorial2} alt="android-tutorial2" />
          </>
        ) : (
          <>
            <div>1. Tap the 3 dots to open the menu.</div>
            <img src={androidTutorial1} alt="android-tutorial1" />
            <br />
            <div>2. Tap 'Add to homescreen'.</div>
            <img src={androidTutorial2} alt="android-tutorial2" />
          </>
        )}
      </div>
    </div>
  );
};

export default AddToHomescreenModal;
