import React from 'react';
import { Switch, Redirect, withRouter } from 'react-router-dom';
import { Route } from '@simple-contacts/react-router-async-routes';
import Dashboard from './components/Dashboard/Dashboard';
import Explore from './components/Explore/Explore';
import Learn from './components/Learn/Learn';
import Journeys from './components/Journeys/Journeys';
import LoggedOutLanding from './components/LoggedOutLanding/LoggedOutLanding';
import Player from './components/Player/Player';
import TracksList from './components/TracksList/TracksList';
import NotFound from './components/NotFound/NotFound';
import axios from 'axios';
import Subscribe from './components/Subscribe/Subscribe';
import Payment from './components/Payment/Payment';
import UpdatePayment from './components/UpdatePayment/UpdatePayment';
import Admin from './components/Admin/Admin';
import ResetPassword from './components/ResetPassword/ResetPassword';

let valid;

const isValidAuth = async () => {
  try {
    valid = await axios.get('/api/auth/valid-token');
    if (valid.status === 200) {
      return true;
    }
  } catch (error) {
    return false;
  }
  return false;
};

export default withRouter((props) => {
  const PrivateRouteDashboard = ({ component: Component, ...rest }) => (
    <Route
      async
      {...rest}
      render={async (props1) =>
        (await isValidAuth()) === true ? (
          <Component userData={valid.data} contentIsFree={props.contentIsFree} handleNav={props.handleNav} {...props1} />
        ) : (
          <Redirect to="/home" />
        )
      }
    />
  );

  const PrivateRouteJourneys = ({ component: Component, ...rest }) => (
    <Route
      async
      {...rest}
      render={async (props1) =>
        (await isValidAuth()) === true ? (
          <Component handleNav={props.handleNav} {...props1} />
        ) : (
          <Redirect to="/home" />
        )
      }
    />
  );

  const PrivateRouteLearn = ({ component: Component, ...rest }) => (
    <Route
      async
      {...rest}
      render={async (props1) =>
        (await isValidAuth()) === true ? (
          <Component handleNav={props.handleNav} {...props1} />
        ) : (
          <Redirect to="/home" />
        )
      }
    />
  );

  const PrivateRouteExplore = ({ component: Component, ...rest }) => (
    <Route
      async
      {...rest}
      render={async (props1) =>
        (await isValidAuth()) === true ? (
          <Component userData={valid.data} handleNav={props.handleNav} {...props1} />
        ) : (
          <Redirect to="/home" />
        )
      }
    />
  );

  const PrivateRouteTracks = ({ component: Component, ...rest }) => (
    <Route
      async
      {...rest}
      render={async (props1) =>
        (await isValidAuth()) === true ? (
          props1.location.state ? (
            <Component userData={valid.data} handleNav={props.handleNav} {...props1} />
          ) : (
            <Redirect to="/" />
          )
        ) : (
          <Redirect to="/home" />
        )
      }
    />
  );

  const PrivateRoutePlayer = ({ component: Component, ...rest }) => (
    <Route
      async
      {...rest}
      render={async (props1) =>
        (await isValidAuth()) === true ? (
          props1.location.state ? (
            <Component userData={valid.data} handleNav={props.handleNav} {...props1} />
          ) : (
            <Redirect to="/journeys" />
          )
        ) : (
          <Redirect to="/home" />
        )
      }
    />
  );

  const RouteLoggedOutLanding = ({ component: Component, ...rest }) => (
    <Route
      async
      {...rest}
      render={async (props1) =>
        (await isValidAuth()) === true ? (
          <Component userData={valid.data} handleNav={props.handleNav} {...props1} />
        ) : (
          <Component handleNav={props.handleNav} {...props1} />
        )
      }
    />
  );

  const PrivateRouteSubscribe = ({ component: Component, ...rest }) => (
    <Route
      async
      {...rest}
      render={async (props1) =>
        (await isValidAuth()) === true ? (
          !valid.data.subscribed ? (
            <Component userData={valid.data} handleNav={props.handleNav} {...props1} />
          ) : (
            <Redirect to="/" />
          )
        ) : (
          <Redirect to="/home" />
        )
      }
    />
  );

  const PrivateRoutePayment = ({ component: Component, ...rest }) => (
    <Route
      async
      {...rest}
      render={async (props1) =>
        (await isValidAuth()) === true ? (
          props1.location.state && !valid.data.subscribed ? (
            <Component userData={valid.data} handleNav={props.handleNav} {...props1} />
          ) : (
            <Redirect to="/" />
          )
        ) : (
          <Redirect to="/home" />
        )
      }
    />
  );

  const PrivateRouteUpdatePayment = ({ component: Component, ...rest }) => (
    <Route
      async
      {...rest}
      render={async (props1) =>
        (await isValidAuth()) === true ? (
          valid.data.subscribed ? (
            <Component userData={valid.data} handleNav={props.handleNav} {...props1} />
          ) : (
            <Redirect to="/" />
          )
        ) : (
          <Redirect to="/home" />
        )
      }
    />
  );

  const PrivateRouteAdmin = ({ component: Component, ...rest }) => (
    <Route
      async
      {...rest}
      render={async (props1) =>
        (await isValidAuth()) === true && valid.data.admin ? (
          <Component userData={valid.data} handleNav={props.handleNav} {...props1} />
        ) : (
          <Redirect to="/home" />
        )
      }
    />
  );

  const RouteResetPassword = ({ component: Component, ...rest }) => (
    <Route
      async
      {...rest}
      render={async (props1) => <Component handleNav={props.handleNav} {...props1} />}
    />
  );

  return (
    <Switch>
      <PrivateRouteDashboard exact path="/" component={Dashboard} />
      <RouteLoggedOutLanding path="/home" component={LoggedOutLanding} />
      <PrivateRouteJourneys path="/journeys" component={Journeys} />
      <PrivateRouteLearn path="/learn" component={Learn} />
      <PrivateRouteExplore path="/explore" component={Explore} />
      <PrivateRouteTracks path="/tracks" component={TracksList} />
      <PrivateRoutePlayer path="/player" component={Player} />
      <PrivateRouteSubscribe path="/subscribe" component={Subscribe} />
      <PrivateRoutePayment path="/payment" component={Payment} />
      <PrivateRouteUpdatePayment path="/update-payment" component={UpdatePayment} />
      <PrivateRouteAdmin path="/admin" component={Admin} />
      <RouteResetPassword path="/reset-password" component={ResetPassword} />
      <Route render={() => <NotFound {...props} />} />
    </Switch>
  );
});
