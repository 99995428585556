import React, { Component } from 'react';

import { withRouter } from 'react-router-dom';
import dismiss from '../../assets/you/x.svg';
import eye from '../../assets/landing/eye.svg';
import axios from 'axios';
import { hasAccess, checkPassword, validateEmail } from '../../common/validation';
import _ from 'lodash/string';

import back from '../../assets/journeys/backarrow@2x.png';

class SettingsModal extends Component {
  state = {
    editName: false,
    editEmail: false,
    cancelSubscription: false,
    firstName: '',
    lastName: ' ',
    email: this.props.userData.email,
    pass1: '',
    pass2: '',
    displayNameFirst: '',
    displayNameLast: '',
    displayEmail: '',
    displaySub: false,
    nameEmpty: false,
    emailEmpty: false,
    passEmpty: false,
    passMatch: true,
    showPass: false,
    emailInvalid: false,
    passInvalid: false,
    userExists: false,
    brand: '',
    last4: '',
  };

  componentDidMount() {
    this.getCardInfo();
  }

  updateName = async () => {
    if (this.state.firstName) {
      await axios.put(`/api/user/name/${this.props.userData.id}`, {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
      });

      this.setState({
        displayNameFirst: this.state.firstName,
        displayNameLast: this.state.lastName,
        editName: false,
      });
    } else {
      this.setState({ nameEmpty: true });
    }
  };

  updateEmailPassword = async () => {
    if (this.state.pass1 !== this.state.pass2) {
      return this.setState({ passMatch: false });
    } else if (!this.state.pass1 || !this.state.pass2) {
      return this.setState({ passEmpty: true });
    } else if (!this.state.email) {
      return this.setState({ emailEmpty: true });
    }

    let emailValid = false;
    let passValid = false;

    if (validateEmail(this.state.email)) {
      emailValid = true;
      this.setState({ emailInvalid: false });
    } else {
      this.setState({ emailInvalid: true });
    }

    if (checkPassword(this.state.pass2)) {
      passValid = true;
      this.setState({ passInvalid: false });
    } else {
      this.setState({ passInvalid: true });
    }

    if (emailValid && passValid) {
      await axios
        .put('/api/auth/login', {
          email: this.state.email.toLowerCase(),
          password: this.state.pass2,
        })
        .then((res) => {
          if (res.status === 200) {
            this.setState({
              displayEmail: this.state.email,
              editEmail: false,
              pass1: '',
              pass2: '',
              showPass: false,
            });
          }
        })
        .catch((err) => {
          if (err.response.status === 403) {
            this.setState({ userExists: true });
          }
        });
    }
  };

  handleChange = (value, type) => {
    this.setState({
      nameEmpty: false,
      passMatch: true,
      passEmpty: false,
      emailEmpty: false,
      userExists: false,
      emailInvalid: false,
      passInvalid: false,
    });

    if (type === 'first') {
      this.setState({ firstName: value });
    } else if (type === 'last') {
      this.setState({ lastName: value });
    } else if (type === 'email') {
      this.setState({ email: value });
    } else if (type === 'pass1') {
      this.setState({ pass1: value });
    } else if (type === 'pass2') {
      this.setState({ pass2: value });
    }
  };

  cancelSubscription = () => {
    axios.put('/api/stripe/cancel-subscription').then(() => this.props.handleReload());
  };

  renewSubscription = () => {
    axios
      .put('/api/stripe/renew-subscription')
      .then(() => {
        this.props.userData.subscribed = true;
        this.setState({ displaySub: false });
      })
      .catch((err) => {
        if (err.response.status === 404) {
          this.props.history.push('/subscribe');
        }
      });
  };

  getCardInfo = async () => {
    axios
      .get('/api/stripe/payment')
      .then((res) => this.setState({ brand: res.data.brand, last4: res.data.last4 }));
  };

  render() {
    return (
      <div id="sign-up-modal-settings" className="sign-up-modal-settings">
        {!this.state.editName && !this.state.editEmail && !this.state.cancelSubscription ? (
          <>
            <div className="settings-nav">
              <button className="logout-button" onClick={() => this.props.logout()}>
                Logout
              </button>
              <h4>Your Account</h4>
              <img
                src={dismiss}
                alt="dismiss"
                onClick={() =>
                  this.props.handleCancel(
                    'settings',
                    this.state.displayNameLast ||
                      this.state.displayNameFirst ||
                      this.state.displayEmail ||
                      this.state.displaySub
                      ? true
                      : false,
                  )
                }
                className="dismiss-settings"
              />
            </div>
            <hr />
            <div className="settings-name">
              <div className="name-left">
                <h5>Name</h5>
                <span>
                  {this.state.displayNameFirst || this.state.displayNameLast
                    ? `${this.state.displayNameFirst} ${this.state.displayNameLast}`
                    : this.props.userData.first_name || this.props.userData.last_name
                    ? `${this.props.userData.first_name} ${this.props.userData.last_name}`
                    : 'unset'}
                </span>
              </div>
              <span onClick={() => this.setState({ editName: true })} className="name-right">
                Edit
              </span>
            </div>
            <hr />
            <div className="settings-email">
              <div className="email-left">
                <h5>Email Address</h5>
                <span>{this.state.displayEmail || this.props.userData.email}</span>
                <div id="break"></div>
                <h5>Password</h5>
                <span>••••••••</span>
              </div>
              <span onClick={() => this.setState({ editEmail: true })} className="email-right">
                Edit
              </span>
            </div>
            {!this.props.contentIsFree ? (
              <>
                <hr />
                <div className="settings-subscription">
                  <div className="subscription-left">
                    <h5>Subscription</h5>
                    {this.props.userData.subscribed && !this.state.displaySub ? (
                      <span>Active - Full access</span>
                    ) : (
                      <span>Inactive</span>
                    )}
                    {this.props.userData.subscribed && !this.state.displaySub ? (
                      <></>
                    ) : (
                      <>
                        {this.props.userData.subscription_end_date === null ? (
                          <></>
                        ) : (
                          <span>{`Access until ${new Date(
                            this.props.userData.subscription_end_date,
                          ).toLocaleDateString('en-US')}`}</span>
                        )}
                      </>
                    )}
                  </div>
                  <div className="subscription-right">
                    <span>$6.58 / mo.</span>
                    {this.props.userData.subscribed && !this.state.displaySub ? (
                      <span
                        className="cancel-sub"
                        onClick={() => this.setState({ cancelSubscription: true })}
                      >
                        Cancel
                      </span>
                    ) : (
                      <>
                        {hasAccess(this.props.userData.subscription_end_date) ? (
                          <span onClick={() => this.renewSubscription()} className="settings-sub">
                            Renew!
                          </span>
                        ) : (
                          <span
                            onClick={() => this.props.history.push('/subscribe')}
                            className="settings-sub"
                          >
                            Subscribe!
                          </span>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
            {this.props.userData.subscribed && !this.props.contentIsFree ? (
              <>
                <hr />
                <div className="settings-billing">
                  <div className="billing-left">
                    <h5>Billing Info</h5>
                    <span>{`${_.upperFirst(this.state.brand)} - ${this.state.last4}`}</span>
                  </div>
                  <span
                    onClick={() => this.props.history.push('/update-payment')}
                    className="billing-right"
                  >
                    Update
                  </span>
                </div>
              </>
            ) : (
              <></>
            )}
            <hr />
            <div className="settings-contact-home-icon">
              <a href="mailto:support@lotimus.com?subject=Support" className="contact">
                Contact us
              </a>
              <span onClick={this.props.handleAddToHomescreenModal} className="add-to-home">
                Add app to homescreen
              </span>
            </div>
          </>
        ) : this.state.editName ? (
          <div className="edit-name-container edit">
            <img
              onClick={() => this.setState({ editName: false })}
              src={back}
              className="back"
              alt="back"
            />
            <span className="title-edit-settings">Update your name</span>
            <div className="first-name inputs">
              {this.state.nameEmpty ? (
                <span className="empty-input">Enter a first name</span>
              ) : (
                <span>First Name</span>
              )}
              <input
                defaultValue={this.state.firstName}
                onChange={(e) => this.handleChange(e.target.value, 'first')}
                type="text"
                spellCheck="false"
              />
            </div>
            <div className="last-name inputs">
              <span>Last Name</span>
              <input
                defaultValue={this.state.lastName}
                onChange={(e) => this.handleChange(e.target.value, 'last')}
                type="text"
                spellCheck="false"
              />
            </div>
            <button onClick={() => this.updateName()} className="save">
              Save
            </button>
          </div>
        ) : this.state.editEmail ? (
          <div className="edit-email-container edit">
            <img
              onClick={() => this.setState({ editEmail: false, showPass: false })}
              src={back}
              className="back"
              alt="back"
            />
            <span className="title-edit-settings">Update email and password</span>
            <div className="email inputs">
              {this.state.emailEmpty || this.state.userExists || this.state.emailInvalid ? (
                this.state.emailEmpty ? (
                  <span className="empty-input">Enter an email</span>
                ) : this.state.userExists ? (
                  <span className="empty-input">User Already Exists</span>
                ) : this.state.emailInvalid ? (
                  <span className="empty-input">* Please enter a valid email address.</span>
                ) : (
                  <> </>
                )
              ) : (
                <span>Email</span>
              )}
              <input
                defaultValue={this.state.displayEmail || this.props.userData.email}
                onChange={(e) => this.handleChange(e.target.value, 'email')}
                type="text"
                spellCheck="false"
              />
            </div>
            <div className="password1 inputs">
              <span>New password</span>

              <input
                defaultValue={this.state.pass1}
                onChange={(e) => this.handleChange(e.target.value, 'pass1')}
                spellCheck="false"
                type={this.state.showPass ? 'text' : 'password'}
              />
              {!this.state.passMatch ||
              this.state.passEmpty ||
              this.state.passInvalid ||
              this.state.emailInvalid ? (
                !this.state.passMatch ? (
                  <span className="empty-input">Passwords do not match</span>
                ) : this.state.passEmpty ? (
                  <span className="empty-input">Enter password</span>
                ) : this.state.passInvalid ? (
                  <span className="empty-input pass-invalid">
                    *6-20 chars with a number, an upper and lowercase letter.
                  </span>
                ) : (
                  <span>Confirm new password</span>
                )
              ) : (
                <span>Confirm new password</span>
              )}
              <div className="pass2-outer">
                <input
                  defaultValue={this.state.pass2}
                  onChange={(e) => this.handleChange(e.target.value, 'pass2')}
                  spellCheck="false"
                  type={this.state.showPass ? 'text' : 'password'}
                />
                <img
                  onClick={() => this.setState({ showPass: !this.state.showPass })}
                  className="eyeball"
                  alt="password-reveal"
                  src={eye}
                />
              </div>
            </div>
            <button onClick={() => this.updateEmailPassword()} className="save">
              Save
            </button>
          </div>
        ) : this.state.cancelSubscription ? (
          <div className="cancel-sub-container edit">
            <img
              onClick={() => this.setState({ cancelSubscription: false })}
              src={back}
              alt="back"
              className="back"
            />
            <span className="title-edit-settings">Are you sure?</span>
            <p>
              Thanks for journeying with us. We’ll be adding new content on a regular basis that you
              might enjoy. We’d like to see you stay!
            </p>
            <br />
            <p>
              Is there any feedback you’d like to provide us instead? We’re always happy to listen.
              <a href="mailto:support@lotimus.com"> Please email support@lotimus.com</a>
            </p>
            <div className="buttons">
              <button
                onClick={() => this.setState({ cancelSubscription: false })}
                className="do-not-cancel save"
              >
                Don't cancel
              </button>
              <button onClick={() => this.cancelSubscription()} className="cancel">
                Cancel Subscription
              </button>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  }
}

export default withRouter(SettingsModal);
