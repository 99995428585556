import React, { Component } from 'react';
import axios from 'axios';
import BlogPost from '../BlogPost/BlogPost';

class Learn extends Component {
  state = {
    blogPosts: [],
  };

  componentDidMount() {
    this.props.handleNav(false);
    axios.get('/api/media/blog-posts').then((res) => {
      this.setState({ blogPosts: res.data });
    });
  }

  render() {
    const posts = this.state.blogPosts.map((el, i) => <BlogPost key={i} data={el} />);
    return (
      <div className="learn-container">
        <h1>Journey Notes</h1>
        <div className="posts-container">{posts}</div>
      </div>
    );
  }
}

export default Learn;
