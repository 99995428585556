import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import axios from 'axios';

import heart from '../../assets/journeys/heart@2x.png';
import play from '../../assets/journeys/playbutton@2x.png';
import heartFilled from '../../assets/journeys/filledHeart.png';
import lock from '../../assets/journeys/fixedlock@2x.png';
import lotus from '../../assets/journeys/lotusStroke.png';
import lotusFilled from '../../assets/journeys/lotusFilled.png';
import { hasAccess } from '../../common/validation';
import ImageFadeIn from 'react-image-fade-in';

class Track extends Component {
  state = {
    length: 0,
    favorite: false,
    listened: false,
  };
  audioEl = {};

  async componentDidMount() {
    if (this.props.data.free || hasAccess(this.props.userData.subscription_end_date)) {
      this.audioEl = document.getElementById(`track-audio-tracks-list-${this.props.data.id}`);
      this.audioEl.onloadedmetadata = () => {
        this.setState({ length: this.audioEl.duration });
      };
    }

    const isFavorite = await axios.get(
      `/api/media/user-track?userId=${this.props.userData.id}&trackId=${this.props.data.id}`,
    );

    if (isFavorite.data.length > 0 && isFavorite.data[0].favorite) {
      this.setState({ favorite: true });
    }

    if (isFavorite.data.length > 0 && isFavorite.data[0].play_count > 0) {
      this.setState({ listened: true });
    }
  }

  formatTime(timeInSeconds) {
    if (timeInSeconds === 0) {
      return `${timeInSeconds} MIN`;
    }
    return `${Math.floor(timeInSeconds / 60)} MIN`;
  }

  async handleFavorite() {
    if (!this.state.favorite) {
      const result = await axios.post(
        `/api/media/user-track?userId=${this.props.userData.id}&trackId=${this.props.data.id}`,
      );

      if (result.status === 200) {
        this.setState({ favorite: true });
      }
    } else {
      const result = await axios.delete(
        `/api/media/user-track?userId=${this.props.userData.id}&trackId=${this.props.data.id}`,
      );

      if (result.status === 200) {
        this.setState({ favorite: false });
      }
    }
  }

  render() {
    const { data } = this.props;
    return (
      <div
        className={
          !data.free && !hasAccess(this.props.userData.subscription_end_date)
            ? 'track-outer-container locked'
            : 'track-outer-container'
        }
        onClick={
          !data.free && !hasAccess(this.props.userData.subscription_end_date)
            ? () => this.props.handleLock('locked')
            : () => this.props.handleLock('unlocked')
        }
      >
        <ImageFadeIn
          className="track-container"
          loadAsBackgroundImage
          src={this.props.data.image_url}
        >
          <div className="content">
            {!data.free && !hasAccess(this.props.userData.subscription_end_date) ? (
              <></>
            ) : (
              <audio
                preload="metadata"
                id={`track-audio-tracks-list-${data.id}`}
                src={`/api/media/audio/${data.id}`}
              />
            )}
            {!data.free && !hasAccess(this.props.userData.subscription_end_date) ? (
              <img src={lock} alt="lock" className="lock" />
            ) : (
              <> </>
            )}

            {!data.free && !hasAccess(this.props.userData.subscription_end_date) ? (
              <> </>
            ) : (
              <>
                <img
                  onClick={() => this.handleFavorite()}
                  src={heart}
                  alt="heart"
                  className="heart"
                />
                <img
                  onClick={() => this.handleFavorite()}
                  src={heartFilled}
                  alt="heart"
                  className={this.state.favorite ? 'heart-filled' : 'not-filled'}
                />
              </>
            )}
            <h1 className="title">{data.title}</h1>
            {!data.free && !hasAccess(this.props.userData.subscription_end_date) ? (
              <div className='spacer'></div>
            ) : (
              <h3 className="length">{this.formatTime(this.state.length)}</h3>
            )}
            <img src={this.state.listened ? lotusFilled : lotus} alt="" className="listened" />
            {!data.free && !hasAccess(this.props.userData.subscription_end_date) ? (
              <> </>
            ) : (
              <img
                onClick={() => this.props.history.push('/player', { trackData: data })}
                src={play}
                alt="play"
                className="play"
              />
            )}
          </div>
        </ImageFadeIn>
      </div>
    );
  }
}

export default withRouter(Track);
