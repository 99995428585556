import React, { Component } from 'react';
import axios from 'axios';

import Categories from '../Categories/Categories';


class Journeys extends Component {
  state = {
    categories: [],
    loading: true,
  };

  componentDidMount() {
    this.props.handleNav(false);
    axios
      .get('/api/media/category')
      .then((res) => this.setState({ categories: res.data, loading: false }));
  }

  render() {
    const categories = this.state.categories
      .filter((category) => !category.coming_soon)
      .map((el, i) => <Categories key={i} data={el} />);
    const categoriesSoon = this.state.categories
      .filter((category) => category.coming_soon)
      .map((el, i) => <Categories key={i} data={el} />);
    return (
      <div className="journeys-container">
        <header>
          <h1 className="title">Choose a path</h1>
          <h3 className="subtitle">Each element will strengthen you in unique ways.</h3>
        </header>
        <main>
          {categories}
          {categoriesSoon}
        </main>
      </div>
    );
  }
}

export default Journeys;
