import React from 'react';
import face from '../../assets/notFound/Face_404@3x.png';

function NotFound(props) {
  props.handleNav(true);
  return (
    <div className="not-found">
      <div className="upper-content">
        <header className="stay-calm">Stay Chill</header>
        <img src={face} alt="face" className="face" />
        <p className="oops">Looks like you've journeyed out of the site. 404 Error.</p>
      </div>
      <button onClick={() => props.history.push('/')} className="home">
        Go home
      </button>
    </div>
  );
}

export default NotFound;
