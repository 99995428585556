import React, { Component } from 'react';
import FavoriteTrack from '../FavoriteTrack/FavoriteTrack';
import SubscribeModal from '../SubscribeModal/SubscribeModal';

import _ from 'lodash/string';
import axios from 'axios';

class Explore extends Component {
  state = {
    tags: [],
    results: [],
    lockModal: false,
    allTracks: true,
  };

  componentDidMount() {
    this.props.handleNav(false);

    axios.get('/api/media/tags').then((res) => {
      this.setState({ tags: res.data });
    });

    this.getAllTracks();

    document.addEventListener('keydown', this.handleKeyDown);
  }

  handleKeyDown = (event) => {
    if (event.keyCode === 27 && this.state.lockModal) {
      this.setState({ lockModal: false });
    }
  };

  handleLock = (type) => {
    if (type === 'unlocked') {
      return;
    }
    this.setState({ lockModal: true });
  };

  handleCancel = () => {
    this.setState({ lockModal: false });
  };

  handleSelect(tagId) {
    this.setState({ results: [] });
    const newState = this.state.tags;

    if (tagId === 'all-tracks') {
      if (!this.state.allTracks) {
        newState.forEach((el) => {
          el.selected = false;
        });
        this.setState({ tags: newState });
        this.setState({ allTracks: true });
        return this.getAllTracks();
      } else if (this.state.allTracks) {
        return this.setState({ allTracks: false, results: [] });
      }
    }
    if (this.state.allTracks) {
      this.setState({ allTracks: false });
    }

    if (!this.state.tags[tagId - 1].selected) {
      newState[tagId - 1].selected = true;
    } else {
      newState[tagId - 1].selected = false;
    }

    // remove the forEach to allow for multiple tag selections at once.

    newState.forEach((el) => {
      if (tagId !== el.id) {
        el.selected = false;
      }
    });

    this.setState({ tags: newState });

    const filteredTags = this.state.tags.filter((tag) => tag.selected);
    let filteredIds = [];

    for (let i = 0; i < filteredTags.length; i++) {
      filteredIds.push(filteredTags[i].id);
    }

    axios
      .post('/api/media/filter', {
        filteredIds,
      })
      .then((res) => {
        this.setState({ results: res.data });
      });
  }

  getAllTracks() {
    axios.get('/api/media/tracks-explore').then((res) => {
      this.setState({ results: res.data });
    });
  }

  render() {
    const tags = this.state.tags.map((el, i) => (
      <div
        onClick={() => this.handleSelect(el.id)}
        className={!this.state.tags[el.id - 1].selected ? `tag ${i}` : `tag selected-tag ${i}`}
        key={i}
      >
        {_.upperFirst(el.tag)}
      </div>
    ));

    const results = this.state.results.map((el, i) => (
      <FavoriteTrack
        handleLock={this.handleLock}
        filter="true"
        userData={this.props.userData}
        data={el}
        key={i}
      />
    ));

    let resultsTitle;
    if (this.state.results.length === 0 || this.state.allTracks) {
      resultsTitle = '';
    } else if (this.state.results.length === 1) {
      resultsTitle = '1 journey is right for you today';
    } else {
      resultsTitle = `${this.state.results.length} journeys are right for you today`;
    }

    return (
      <div className="explore-container">
        <div className="top">
          <div className="filter-title">What do you need today?</div>
          <div className="filter-subtitle">
            Select the tags below to find the right journey for you
          </div>
        </div>
        <div className="filter">
          <div
            onClick={() => this.handleSelect('all-tracks')}
            className={!this.state.allTracks ? 'tag' : 'tag selected-tag'}
          >
            All Journeys
          </div>
          {tags}
          <div className="results-title">{resultsTitle}</div>
        </div>
        <div className="results">
          <div className="results-title-mobile">{resultsTitle}</div>
          {results}
          <div className="buffer"></div>
        </div>
        <div className={this.state.lockModal ? 'dark-bg-settings visible' : 'dark-bg-settings'}>
          <SubscribeModal handleCancel={this.handleCancel} />
        </div>
      </div>
    );
  }
}

export default Explore;
