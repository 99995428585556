import React, { Component } from 'react';
import axios from 'axios';
import back from '../../assets/journeys/backarrow@2x.png';

class ForgotPassword extends Component {
  state = {
    forgotEmail: '',
    msg: '',
  };

  handleSubmit = () => {
    axios
      .post('/api/user/forgot-password', {
        email: this.state.forgotEmail.toLowerCase().trim(),
      })
      .then((res) => {
        if (res.data === 'not a valid email') {
          this.setState({ msg: 'Please enter a valid email' });
        } else if (res.data === 'email failed') {
          this.setState({ msg: 'There was a problem sending the email' });
        } else if (res.data === 'success') {
          this.setState({ msg: 'A reset password email was sent' });
        }
      });
  };

  render() {
    return (
      <div id="forgot-pass" className="forgot-pass">
        <img onClick={() => this.props.back()} src={back} className="back" alt="back" />
        <span>Please enter the email address that you used to create your account</span>
        {this.state.msg ? (
          <div
            style={
              this.state.msg === 'A reset password email was sent'
                ? { color: 'green' }
                : { color: 'red' }
            }
            className="msg"
          >
            {this.state.msg}
          </div>
        ) : (
          <div className="msg"></div>
        )}
        <div className="inputs">
          <input
            onChange={(e) => this.setState({ msg: '', forgotEmail: e.target.value })}
            type="email"
            placeholder="Enter your email address"
          />
          <button onClick={() => this.handleSubmit(this.state.forgotEmail)}>Reset Password</button>
        </div>
      </div>
    );
  }
}

export default ForgotPassword;
