import React, { Component } from 'react';
import axios from 'axios';
import { BounceLoader } from 'react-spinners';
import { css } from '@emotion/core';
import { withRouter } from 'react-router-dom';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

class AdminBonusTrack extends Component {
  state = {
    tracks: [],
    parentTrack: '',
    parentTrackId: null,
    exerciseTitle: '',
    selectedExerciseFile: null,
    selectedCommentaryFile: null,
    loading: false,
  };

  handleFile(type, file) {
    switch (type) {
      case 'exercise-file':
        this.setState({ selectedExerciseFile: file });
        break;
      case 'commentary-file':
        this.setState({ selectedCommentaryFile: file });
        break;
      default:
        break;
    }
  }

  handleChange(value) {
    this.setState({ exerciseTitle: value });
  }

  handleSubmit() {
    if (
      !this.state.parentTrackId ||
      !this.state.parentTrack ||
      !this.state.exerciseTitle ||
      !this.state.selectedExerciseFile ||
      !this.state.selectedCommentaryFile
    ) {
      return window.alert('Please fill everything out');
    }

    const submitButton = document.getElementById('submit');
    submitButton.disabled = true;

    this.setState({ loading: true });

    const formData = new FormData();

    formData.append(
      'exercise_file',
      this.state.selectedExerciseFile,
      this.state.selectedExerciseFile.name,
    );
    formData.append(
      'commentary_file',
      this.state.selectedCommentaryFile,
      this.state.selectedCommentaryFile.name,
    );
    formData.append('exercise_title', this.state.exerciseTitle);
    formData.append('parent_track_id', this.state.parentTrackId);
    formData.append('parent_track_title', this.state.parentTrack);

    axios
      .post('/api/admin/bonus-track', formData)
      .then((res) => {
        if (res.status === 200) {
          window.alert('bonus track added successfully!');
          this.setState(
            {
              parentTrack: '',
              parentTrackId: null,
              exerciseTitle: '',
              selectedExerciseFile: null,
              selectedCommentaryFile: null,
              loading: false,
            },
            () => {
              document.getElementById('exercise-file').value = null;
              document.getElementById('commentary-file').value = null;
              submitButton.disabled = false;
            },
          );
        } else {
          window.alert('unknown error');
          submitButton.disabled = false;
          this.setState({ loading: false });
        }
      })
      .catch((err) => this.props.history.push('/home'));
  }

  componentDidMount() {
    axios.get('/api/admin/tracks').then((res) => this.setState({ tracks: res.data }));
  }

  render() {
    const tracks = this.state.tracks.map((el, i) => (
      <div onClick={() => this.setState({ parentTrack: el.title, parentTrackId: el.id })} key={i}>
        {el.title}
      </div>
    ));
    return (
      <div className="admin-bonus-track-container">
        <h3>Add Bonus Track: </h3>
        <div className="parent-tracks">
          <span>Parent Track: </span> <span className="parent-track">{this.state.parentTrack}</span>
          <div className="tracks-container">{tracks}</div>
        </div>

        <div className="uploads">
          <span>Bonus track title: </span>
          <input
            value={this.state.exerciseTitle}
            placeholder="Keep it very short"
            onChange={(e) => this.handleChange(e.target.value)}
            type="text"
          />
          <span>Choose bonus track file </span>
          <input
            id="exercise-file"
            onChange={(e) => this.handleFile('exercise-file', e.target.files[0])}
            type="file"
          />

          <span>Choose commentary file </span>
          <input
            id="commentary-file"
            onChange={(e) => this.handleFile('commentary-file', e.target.files[0])}
            type="file"
          />
        </div>

        <button id="submit" onClick={() => this.handleSubmit()} className="submit">
          Submit
        </button>
        {this.state.loading ? (
          <div className="loading-results-admin">
            <BounceLoader
              color={'#a89cd9'}
              css={override}
              sizeUnit={'px'}
              size={75}
              loading={this.state.loading}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  }
}

export default withRouter(AdminBonusTrack);
