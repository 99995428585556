import React from 'react';
import { withRouter } from 'react-router-dom';
import ImageFadeIn from 'react-image-fade-in';

import comingSoon from '../../assets/journeys/comingSoon.png';

function Categories(props) {
  const { data } = props;
  return (
    <div className="categories-outer-container">
      {data.coming_soon ? (
        <div className="coming-soon-outer-container">
          <div className={`coming-soon-container journey`}>
            <ImageFadeIn src={comingSoon} alt="coming soon" className="coming-soon-image" />
            <div className={`${data.cat_title}-text`}>
              <h1
                className={`coming-soon-title title`}
              >{`${data.cat_title.toUpperCase()} JOURNEYS`}</h1>
              <div className={`${data.cat_title}-subtitle subtitle`}></div>
            </div>
          </div>
        </div>
      ) : (
        <div
          onClick={() => props.history.push('/tracks', { category: data })}
          className="categories-container"
        >
          <div className={`${data.cat_title}-container journey`}>
            <ImageFadeIn
              src={data.image_url}
              alt={data.cat_title}
              className={`${data.cat_title}-image`}
            />
            <div className={`${data.cat_title}-text`}>
              <h1
                className={`${data.cat_title}-title title`}
              >{`${data.cat_title.toUpperCase()} JOURNEYS`}</h1>
              <h3 className={`${data.cat_title}-subtitle subtitle`}>{data.subtitle}</h3>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default withRouter(Categories);
