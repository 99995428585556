import React, { Component } from 'react';
import axios from 'axios';
import { BounceLoader } from 'react-spinners';
import { css } from '@emotion/core';
import { withRouter } from 'react-router-dom';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

class AdminTrack extends Component {
  state = {
    categoryId: null,
    trackTitle: '',
    description: '',
    freeTrack: false,
    ben1: '',
    ben2: '',
    ben3: '',
    cats: [],
    category: '',
    tags: [],
    tagIds: [],
    selectedAudioFile: null,
    selectedMusicFile: null,
    selectedImageFile: null,
    loading: false,
  };

  componentDidMount() {
    this.getTags();
    axios.get('/api/media/category').then((res) => this.setState({ cats: res.data }));
  }

  getTags() {
    axios.get('/api/media/tags').then((res) => this.setState({ tags: res.data }));
  }

  handleChange(value, type) {
    if (type === 'title') {
      this.setState({ trackTitle: value });
    } else if (type === 'ta') {
      this.setState({ description: value });
    } else if (type === 'ben1') {
      this.setState({ ben1: value });
    } else if (type === 'ben2') {
      this.setState({ ben2: value });
    } else if (type === 'ben3') {
      this.setState({ ben3: value });
    }
  }

  handleSelect(tagId) {
    const newState = this.state.tags;

    if (!this.state.tags[tagId - 1].selected) {
      newState[tagId - 1].selected = true;
    } else {
      newState[tagId - 1].selected = false;
    }

    this.setState({ tags: newState });

    const filteredTags = this.state.tags.filter((tag) => tag.selected);
    let filteredIds = [];

    for (let i = 0; i < filteredTags.length; i++) {
      filteredIds.push(filteredTags[i].id);
    }

    this.setState({ tagIds: filteredIds });
  }

  handleFile(type, file) {
    switch (type) {
      case 'audio-file':
        this.setState({ selectedAudioFile: file });
        break;
      case 'music-file':
        this.setState({ selectedMusicFile: file });
        break;
      case 'image-file':
        this.setState({ selectedImageFile: file });
        break;
      default:
        break;
    }
  }

  async handleSubmit() {
    if (
      !this.state.categoryId ||
      !this.state.trackTitle ||
      !this.state.description ||
      !this.state.ben1 ||
      !this.state.ben2 ||
      !this.state.ben3 ||
      !this.state.selectedAudioFile ||
      !this.state.selectedMusicFile ||
      !this.state.selectedImageFile ||
      this.state.tagIds.length < 1
    ) {
      return window.alert('Please fill everything out');
    }

    const submitButton = document.getElementById('submit');
    submitButton.disabled = true;

    this.setState({ loading: true });

    const formData = new FormData();

    formData.append('audio_file', this.state.selectedAudioFile, this.state.selectedAudioFile.name);
    formData.append('music_file', this.state.selectedMusicFile, this.state.selectedMusicFile.name);
    formData.append('image_file', this.state.selectedImageFile, this.state.selectedImageFile.name);
    formData.append('track_title', this.state.trackTitle);
    formData.append('category_title', this.state.category);
    formData.append('category_id', this.state.categoryId);
    formData.append('description', this.state.description);
    formData.append('free_track', this.state.freeTrack);
    formData.append('benefit1', this.state.ben1);
    formData.append('benefit2', this.state.ben2);
    formData.append('benefit3', this.state.ben3);
    formData.append('tag_ids', this.state.tagIds);

    axios
      .post('/api/admin/track', formData)
      .then((res) => {
        if (res.status === 200) {
          window.alert('track added successfully!');
          this.getTags();
          this.setState(
            {
              categoryId: null,
              trackTitle: '',
              description: '',
              freeTrack: false,
              ben1: '',
              ben2: '',
              ben3: '',
              category: '',
              tagIds: [],
              selectedAudioFile: null,
              selectedMusicFile: null,
              selectedImageFile: null,
              loading: false,
            },
            () => {
              submitButton.disabled = false;
              document.getElementById('audio-file').value = null;
              document.getElementById('music-file').value = null;
              document.getElementById('image-file').value = null;
            },
          );
        } else {
          window.alert('unknown error');
          submitButton.disabled = false;
          this.setState({ loading: false });
        }
      })
      .catch((err) => this.props.history.push('/home'));
  }

  render() {
    const categories = this.state.cats.map((el, i) => (
      <div
        className={el.coming_soon ? 'hide' : 'admin-category'}
        onClick={() => this.setState({ category: el.cat_title, categoryId: el.id })}
        key={i}
      >
        {el.cat_title}
      </div>
    ));
    const tags = this.state.tags.map((el, i) => (
      <div
        className={el.selected ? 'selected-tag admin-tag' : 'admin-tag'}
        onClick={() => this.handleSelect(el.id)}
        key={i}
      >
        {el.tag}
      </div>
    ));
    return (
      <div className="admin-track-container">
        <h3>Add and Publish New Track</h3>

        <div className="track-title">
          <span>Enter the track title: </span>
          <input
            value={this.state.trackTitle}
            onChange={(e) => this.handleChange(e.target.value, 'title')}
            placeholder="e.g. Wise Child"
            type="text"
          />
        </div>
        <div className="categories-container">
          <span>Journey: </span> <span className="category-span">{this.state.category}</span>
          <div className="categories-inner">{categories}</div>
        </div>

        <div className="uploads">
          <span>Upload Files: </span>
          <div className="s3-upload">
            <span>Choose Audio File </span>
            <input
              id="audio-file"
              onChange={(e) => this.handleFile('audio-file', e.target.files[0])}
              type="file"
            />
          </div>
          <div className="s3-upload">
            <span>Choose Music File </span>

            <input
              id="music-file"
              onChange={(e) => this.handleFile('music-file', e.target.files[0])}
              type="file"
            />
          </div>
          <div className="s3-upload">
            <span>Choose Image File </span>

            <input
              id="image-file"
              onChange={(e) => this.handleFile('image-file', e.target.files[0])}
              type="file"
            />
          </div>
        </div>
        <div className="free-container">
          <span>Is this a free track? </span>
          <button
            className={this.state.freeTrack ? 'enabled free-track-button' : 'free-track-button'}
            onClick={() => this.setState({ freeTrack: true })}
          >
            yes
          </button>
          <button
            className={this.state.freeTrack ? 'free-track-button' : 'enabled free-track-button'}
            onClick={() => this.setState({ freeTrack: false })}
          >
            no
          </button>
        </div>
        <div className="description-container">
          <span>Track Description:</span>
          <textarea
            value={this.state.description}
            onChange={(e) => this.handleChange(e.target.value, 'ta')}
            placeholder="Description"
            cols="40"
            rows="10"
          />
        </div>
        <div className="tags-container">
          <span>Tags: </span>
          <div className="tags-inner">{tags}</div>
        </div>
        <div className="benefits-container">
          <span>Track Benefits: </span>
          <span>Benefit 1: </span>
          <input
            value={this.state.ben1}
            onChange={(e) => this.handleChange(e.target.value, 'ben1')}
            placeholder="Keep it short"
            type="text"
          />
          <span>Benefit 2: </span>
          <input
            value={this.state.ben2}
            onChange={(e) => this.handleChange(e.target.value, 'ben2')}
            placeholder="Keep it short"
            type="text"
          />
          <span>Benefit 3: </span>
          <input
            value={this.state.ben3}
            onChange={(e) => this.handleChange(e.target.value, 'ben3')}
            placeholder="Keep it short"
            type="text"
          />
        </div>
        <button id="submit" onClick={() => this.handleSubmit()} className="submit">
          Submit
        </button>
        {this.state.loading ? (
          <div className="loading-results-admin">
            <BounceLoader
              color={'#a89cd9'}
              css={override}
              sizeUnit={'px'}
              size={75}
              loading={this.state.loading}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  }
}

export default withRouter(AdminTrack);
