import React, { Component } from 'react';

import FavoriteTrack from '../FavoriteTrack/FavoriteTrack';
import settings from '../../assets/you/settings.png';
import SubscribeModal from '../SubscribeModal/SubscribeModal';
import SettingsModal from '../SettingsModal/SettingsModal';
import favoritesDefault from '../../assets/you/favoritesDefault.png';
import AddToHomescreenModal from '../AddToHomescreenModal/AddToHomescreenModal';
import dismiss from '../../assets/you/x.svg';
import { hasAccess } from '../../common/validation';
import ImageFadeIn from 'react-image-fade-in';

import cookie from 'js-cookie';
import _ from 'lodash';

import axios from 'axios';

class Dashboard extends Component {
  state = {
    modal: false,
    lockModal: false,
    addToHomescreenModal: false,
    favorites: [],
    upNext: [],
    frequent: [],
    registered: this.props.location.state && this.props.location.state.registered,
    firstName: '',
    lastName: '',
    displayName: this.props.userData.first_name,
    favoritesChecked: false,
    frequentChecked: false,
    install: false,
    paymentFailed: this.props.userData.last_payment_failed,
    checkedNext: false,
  };

  componentDidMount() {
    this.props.handleNav(false);

    document.addEventListener('keydown', this.handleKeyDown);

    window.addEventListener('beforeinstallprompt', (event) => {
      window.deferredPrompt = event;

      this.setState({ install: true });
    });

    this.getUpNext();
    this.getFrequent();
    this.getFavorites();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.modal !== this.state.modal) {
      if (this.state.modal) {
        window.addEventListener('click', this.clickEventSettings);
      }
    }
  }

  async getUpNext() {
    const lastPlayed = await axios.get(`/api/media/last/${this.props.userData.id}`);

    if (lastPlayed.data.length > 0) {
      const nextTrack = await axios.get(
        `/api/media/track?catId=${lastPlayed.data[0].category_id}&trackId=${lastPlayed.data[0].track_id}`,
      );
      if (
        _.get(nextTrack, 'data[0].free') ||
        hasAccess(this.props.userData.subscription_end_date)
      ) {
        this.setState({ upNext: nextTrack.data[0] });
      }
    }

    this.setState({ checkedNext: true });
  }

  getFrequent() {
    axios.get(`/api/media/frequent/${this.props.userData.id}`).then((res) => {
      this.setState({ frequent: res.data, frequentChecked: true });
    });
  }

  getFavorites() {
    axios.get(`/api/media/favorites/${this.props.userData.id}`).then((res) => {
      this.setState({ favorites: res.data, favoritesChecked: true });
    });
  }

  handleKeyDown = (event) => {
    if (event.keyCode === 27 && this.state.modal) {
      this.setState({ modal: false });
      window.removeEventListener('click', this.clickEvent);
    } else if (event.keyCode === 27 && this.state.lockModal) {
      this.setState({ lockModal: false });
    } else if (event.keyCode === 27 && this.state.registered) {
      this.props.history.push('/');
    }
  };

  handleSettings = (event) => {
    event.stopPropagation();
    this.setState({ modal: true });
  };

  handleLock = (type) => {
    if (type === 'unlocked') {
      return;
    }
    this.setState({ lockModal: true });
  };

  handleCancel = (type, dataUpdated) => {
    if (type === 'settings') {
      if (dataUpdated === true) {
        window.location.reload();
      }
      this.setState({ modal: false });
    } else {
      this.setState({ lockModal: false });
    }
  };

  addName = async () => {
    await axios.put(`/api/user/name/${this.props.userData.id}`, {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
    });

    this.props.history.push('/');
  };

  logout = () => {
    cookie.remove('token');
    window.location.reload();
  };

  handleInstall = () => {
    if (this.state.install && window.deferredPrompt) {
      window.deferredPrompt.prompt();
    } else {
      this.setState({ addToHomescreenModal: true });
    }
  };

  handleReload = () => {
    window.location.reload();
  };

  render() {
    const favorites = this.state.favorites.map((el, i) => (
      <FavoriteTrack
        handleLock={this.handleLock}
        dashboard="true"
        userData={this.props.userData}
        data={el}
        key={i}
      />
    ));
    const frequent = this.state.frequent.map((el, i) => (
      <FavoriteTrack
        handleLock={this.handleLock}
        frequent="true"
        dashboard="true"
        userData={this.props.userData}
        data={el}
        key={i}
      />
    ));
    return (
      <div className="dashboard-container-outer">
        {this.state.addToHomescreenModal ? (
          <AddToHomescreenModal
            type="settings"
            handleAddToHomescreenModal={() => this.setState({ addToHomescreenModal: false })}
          />
        ) : (
          <></>
        )}
        <div className="dashboard-container">
          <div className="settings">
            <div className="text">Most Frequented</div>
            <div className="text-favorites">Favorites</div>
            <div className="settings-left">
              <div className="settings-welcome">
                {this.state.displayName
                  ? `Welcome, ${this.state.displayName}`
                  : 'Welcome, journeyer'}
              </div>
            </div>
            <div onClick={this.handleSettings} className="settings-right">
              <ImageFadeIn src={settings} alt="settings" className="settings-image" />
              <div>Settings</div>
            </div>
          </div>
          <div className={!this.state.checkedNext ? 'up-next hidden' : 'up-next'}>
            {!this.state.upNext || this.state.upNext.length < 1 ? (
              <> </>
            ) : (
              <div className="up-next-left">
                <div className="up-next-text">Up Next:</div>
                <div className="next-track">
                  {this.state.upNext && this.state.upNext.title
                    ? this.state.upNext.title
                    : 'Start a journey'}
                </div>
              </div>
            )}
            <div className="up-next-right">
              {!this.state.upNext || this.state.upNext.length < 1 ? (
                <button
                  className="dashboard-button"
                  onClick={() => this.props.history.push('/journeys')}
                >
                  Start
                </button>
              ) : (
                <button
                  className="dashboard-button"
                  onClick={() =>
                    this.props.history.push('/player', {
                      trackData: this.state.upNext,
                      dashboard: true,
                    })
                  }
                >
                  Continue
                </button>
              )}
            </div>
          </div>
          <div className="favorites">
            {!this.state.favoritesChecked ? (
              <></>
            ) : this.state.favorites.length > 0 ? (
              <>
                {favorites}
                <div className="buffer"></div>
              </>
            ) : (
              <>
                <ImageFadeIn
                  src={favoritesDefault}
                  alt="favorites-default"
                  className="favorites-default"
                />
              </>
            )}
          </div>
          <div className="frequent">
            {!this.state.frequentChecked ? (
              <></>
            ) : this.state.frequent.length > 0 ? (
              <>
                {frequent}
                <div className="buffer"></div>
              </>
            ) : (
              <>
                <div className="frequent-text">
                  <span className="repeat">We encourage repeat listens!</span>
                  <p>
                    The more you listen to a particular journey, the more it becomes integrated into
                    your own self-story.
                  </p>
                </div>
              </>
            )}
          </div>
        </div>
        <div className={this.state.modal ? 'dark-bg-settings visible' : 'dark-bg-settings'}>
          <SettingsModal
            contentIsFree={this.props.contentIsFree}
            handleCancel={this.handleCancel}
            handleAddToHomescreenModal={() =>
              this.setState({ modal: false }, () => {
                this.handleInstall();
              })
            }
            handleReload={this.handleReload}
            userData={this.props.userData}
            logout={this.logout}
          />
        </div>
        <div className={this.state.lockModal ? 'dark-bg-settings visible' : 'dark-bg-settings'}>
          <SubscribeModal handleCancel={this.handleCancel} />
        </div>
        <div className={this.state.registered ? 'dark-bg-settings visible' : 'dark-bg-settings'}>
          <div id="add-name-modal" className="add-name-modal">
            <span className="welcome">Hey there! What's your name?</span>
            <div className="first-name">
              <span>First Name</span>
              <input
                defaultValue={this.state.firstName}
                onChange={(e) => this.setState({ firstName: e.target.value })}
                type="text"
                spellCheck="false"
              />
            </div>
            <div className="last-name">
              <span>Last Name</span>
              <input
                defaultValue={this.state.lastName}
                onChange={(e) => this.setState({ lastName: e.target.value })}
                type="text"
                spellCheck="false"
              />
            </div>
            <div className="buttons">
              <button onClick={() => this.addName()} className="later">
                Later
              </button>
              <button onClick={() => this.addName()} className="submit">
                Submit
              </button>
            </div>
          </div>
        </div>
        {this.state.paymentFailed ? (
          <div className="payment-failed-outer">
            <div
              onClick={() => this.props.history.push('/update-payment')}
              className="payment-failed"
            >
              Last payment failed. Click here to update payment info.
            </div>
            <ImageFadeIn
              src={dismiss}
              alt="dismiss"
              onClick={() => this.setState({ paymentFailed: false })}
              className="dismiss"
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  }
}

export default Dashboard;
