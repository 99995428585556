import React from 'react';

import lotimus from '../../assets/landing/lotimus.png';
import lotimusLogo from '../../assets/nav/logo@3x.png';
import { isIos } from '../../common/validation';

import { withRouter } from 'react-router-dom';

function SubscribeModal(props) {
  return (
    <div className="subscribe-modal-container">
      {isIos() ? (
        <></>
      ) : (
        <div className="logo-modal">
          <img src={lotimusLogo} alt="logo" className="logo-image-modal" />
          <img src={lotimus} alt="logo" className="logo-text-modal" />
        </div>
      )}
      <div className="title-modal">Subscribe and get access to everything!</div>
      <main>
        <p>
          We’ll be adding new content regularly to keep your journey going. For <strong>$7.95/mo</strong> or <strong>$78.95/yr</strong> ($6.58/mo) we give you
          access to current content and new releases.
        </p>
        <ul>
          <li>All journeys — New ones coming monthly!</li>
          <li>All short stories — Revisit your favorite worlds</li>
          <li>
            All music tracks (without speech) — Great for getting into deep states of focused
            alertness, relaxation, or sleep.
          </li>
        </ul>
      </main>
      <div className="modal-buttons-container">
        <button className="confirm" onClick={() => props.history.push('/subscribe')}>
          Yeah, let's try it!
        </button>
        <button onClick={() => props.handleCancel()} className="later">
          Maybe later :)
        </button>
      </div>
    </div>
  );
}

export default withRouter(SubscribeModal);
