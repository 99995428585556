import React, { Component } from 'react';
import axios from 'axios';
import eye from '../../assets/landing/eye.svg';
import { checkPassword } from '../../common/validation';

class ResetPassword extends Component {
  state = {
    allowReset: false,
    password: '',
    passConfirm: '',
    passMatch: true,
    passInvalid: false,
    passUpdated: false,
  };

  componentDidMount() {
    const uuid = this.props.location.search.replace('?uuid=', '');
    axios.post('/api/user/find-uuid', { uuid }).then((res) => {
      if (res.data === 'valid') {
        this.setState({ allowReset: true });
      } else if (res.data === 'invalid') {
        this.props.history.push('/home');
      }
    });
  }

  async resetPass() {
    this.setState({
      passMatch: true,
      passInvalid: false,
    });

    let passValid = false;

    if (checkPassword(this.state.password)) {
      passValid = true;
      this.setState({ passInvalid: false });
    } else {
      this.setState({ passInvalid: true });
    }

    if (this.state.password !== this.state.passConfirm) {
      return this.setState({ passMatch: false });
    }

    if (passValid) {
      await axios
        .post('/api/user/reset-password', {
          uuid: this.props.location.search.replace('?uuid=', ''),
          password: this.state.password,
        })
        .then((res) => {
          if (res.data === 'password updated') {
            this.setState({ passUpdated: true });
            setTimeout(() => this.props.history.push('/home'), 5000);
          } else if (res.data === 'error') {
            this.props.history.push('/home');
          }
        });
    }
  }

  handleEnter(key) {
    if (key === 13) {
      this.resetPass();
    }
  }

  render() {
    return (
      <div>
        {this.state.allowReset ? (
          <div className="reset-container">
            <div className="inputs-container">
              <h3>Enter a New Password</h3>
              <div className="password">
                <span>New Password</span>
                <input
                  onKeyDown={(e) => this.handleEnter(e.keyCode)}
                  value={this.state.password}
                  onChange={(e) => this.setState({ password: e.target.value })}
                  type={this.state.showPass ? 'text' : 'password'}
                  className="password"
                />
                <img
                  onClick={() => this.setState({ showPass: !this.state.showPass })}
                  className="eyeball"
                  alt="password-reveal"
                  src={eye}
                />
              </div>
              <div className="password">
                <span>Confirm New Password</span>
                <input
                  onKeyDown={(e) => this.handleEnter(e.keyCode)}
                  value={this.state.passConfirm}
                  onChange={(e) => this.setState({ passConfirm: e.target.value })}
                  type={this.state.showPass ? 'text' : 'password'}
                  className="password"
                />
              </div>
              <div className="spc"></div>
              <button onClick={() => this.resetPass()} className="reset-button">
                Reset Password
              </button>
              {this.state.passInvalid || !this.state.passMatch || this.state.passUpdated ? (
                this.state.passInvalid ? (
                  <span className="pass-invalid">
                    *Password must have 6-20 characters with a number, an upper and a lowercase
                    letter
                  </span>
                ) : !this.state.passMatch ? (
                  <span className="pass-invalid">*Passwords do not match</span>
                ) : this.state.passUpdated ? (
                  <span className="pass-invalid" style={{ color: 'green' }}>
                    Password Updated! Redirecting you to the homepage in 5 seconds.
                  </span>
                ) : (
                  <></>
                )
              ) : (
                <></>
              )}
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  }
}

export default ResetPassword;
