import React, { Component } from 'react';
import AdminTrack from '../Admin/AdminTrack';
import AdminBonusTrack from '../Admin/AdminBonusTrack';
import AdminJourney from '../Admin/AdminJourney';
import AdminTag from '../Admin/AdminTag';
import AdminBlog from '../Admin/AdminBlog';
import AdminPublish from '../Admin/AdminPublish';
import AdminUserList from '../Admin/AdminUserList';
import AdminUserSearch from './AdminUserSearch';

class Admin extends Component {
  state = {
    publishTrack: false,
    newTrack: false,
    newBonusTrack: false,
    newJourney: false,
    newTag: false,
    newBlog: false,
    userList: false,
    userSearch: false,
  };

  render() {
    return (
      <div className="admin-container">
        <button className="go-back" onClick={() => this.props.history.goBack()}>
          Go Back
        </button>
        <h1>For Admins</h1>
        <h3>Please check each point of data carefully before submitting.</h3>
        <h3>A mistake will publish to the live site immediately!</h3>
        <div className="admin-buttons">
          <button
            onClick={() => this.setState({ publishTrack: !this.state.publishTrack })}
            className={this.state.publishTrack ? 'add-track on' : 'add-track'}
          >
            {this.state.publishTrack ? 'Close Publishing' : 'Track Publishing'}
          </button>
          <button
            onClick={() => this.setState({ newTrack: !this.state.newTrack })}
            className={this.state.newTrack ? 'add-track on' : 'add-track'}
          >
            {this.state.newTrack ? 'Close Track' : 'Add and Publish New Track'}
          </button>
          <button
            onClick={() => this.setState({ newBonusTrack: !this.state.newBonusTrack })}
            className={this.state.newBonusTrack ? 'add-track on' : 'add-track'}
          >
            {this.state.newBonusTrack ? 'Close Bonus Track' : 'Add New Bonus Track'}
          </button>
          <button
            onClick={() => this.setState({ newJourney: !this.state.newJourney })}
            className={this.state.newJourney ? 'add-track on' : 'add-track'}
          >
            {this.state.newJourney ? 'Close Journey' : 'Add New Journey (Category)'}
          </button>
          <button
            onClick={() => this.setState({ newTag: !this.state.newTag })}
            className={this.state.newTag ? 'add-track on' : 'add-track'}
          >
            {this.state.newTag ? 'Close Tag' : 'Add New Tag'}
          </button>
          <button
            onClick={() => this.setState({ newBlog: !this.state.newBlog })}
            className={this.state.newBlog ? 'add-track on' : 'add-track'}
          >
            {this.state.newBlog ? 'Close Blog' : 'Add New Blog Post'}
          </button>
          <button
            onClick={() => this.setState({ userList: !this.state.userList })}
            className={this.state.userList ? 'add-track on' : 'add-track'}
          >
            {this.state.userList ? 'Close User List' : 'Generate Users CSV'}
          </button>
          <button
            onClick={() => this.setState({ userSearch: !this.state.userSearch })}
            className={this.state.userSearch ? 'add-track on' : 'add-track'}
          >
            {this.state.userSearch ? 'Close User Access' : 'Give a User Free Access'}
          </button>
        </div>
        {this.state.publishTrack ? <AdminPublish /> : <></>}
        {this.state.newTrack ? <AdminTrack /> : <></>}
        {this.state.newBonusTrack ? <AdminBonusTrack /> : <></>}
        {this.state.newJourney ? <AdminJourney /> : <></>}
        {this.state.newTag ? <AdminTag /> : <></>}
        {this.state.newBlog ? <AdminBlog /> : <></>}
        {this.state.userList ? <AdminUserList /> : <></>}
        {this.state.userSearch ? <AdminUserSearch /> : <></>}
      </div>
    );
  }
}

export default Admin;
