import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import axios from 'axios';

import heartFilled from '../../assets/journeys/filledHeart.png';
import heart from '../../assets/journeys/heart@2x.png';
import play from '../../assets/player/favoritePlay.png';
import lock from '../../assets/journeys/fixedlock@2x.png';
import { hasAccess } from '../../common/validation';
import ImageFadeIn from 'react-image-fade-in';

class FavoriteTrack extends Component {
  state = {
    favorite: this.props.dashboard && !this.props.frequent ? true : false,
    dashboard: this.props.dashboard ? true : false,
    frequent: this.props.frequent ? true : false,
    filter: this.props.filter ? true : false,
  };

  async componentDidMount() {
    if (!this.state.favorite) {
      const isFavorite = await axios.get(
        `/api/media/user-track?userId=${this.props.userData.id}&trackId=${this.props.data.id}`,
      );

      if (isFavorite.data.length > 0 && isFavorite.data[0].favorite) {
        this.setState({ favorite: true });
      }
    }
  }

  async handleFavorite() {
    if (this.state.favorite) {
      const result = await axios.delete(
        `/api/media/user-track?userId=${this.props.userData.id}&trackId=${this.props.data.id}`,
      );

      if (result.status === 200) {
        this.setState({ favorite: false });
      }
    } else {
      const result = await axios.post(
        `/api/media/user-track?userId=${this.props.userData.id}&trackId=${this.props.data.id}`,
      );

      if (result.status === 200) {
        this.setState({ favorite: true });
      }
    }
  }

  render() {
    return (
      <ImageFadeIn
        loadAsBackgroundImage
        src={this.props.data.image_url}
        id={
          this.state.frequent
            ? `frequent-track-container-${this.props.data.id}`
            : `favorite-track-container-${this.props.data.id}`
        }
        className={
          !this.props.data.free && !hasAccess(this.props.userData.subscription_end_date)
            ? 'favorite-track-container locked'
            : 'favorite-track-container'
        }
        onClick={
          !this.props.data.free && !hasAccess(this.props.userData.subscription_end_date)
            ? () => this.props.handleLock('locked')
            : () => this.props.handleLock('unlocked')
        }
      >
        <div className="content">
          {this.state.frequent ? (
            <div className="play-count">{this.props.data.play_count}</div>
          ) : (
            <></>
          )}
          {!this.props.data.free && !hasAccess(this.props.userData.subscription_end_date) ? (
            <img src={lock} alt="lock" className="lock-favorite" />
          ) : (
            <></>
          )}
          {!this.props.data.free && !hasAccess(this.props.userData.subscription_end_date) ? (
            <></>
          ) : this.props.frequent ? (
            <> </>
          ) : (
            <>
              <img
                onClick={() => this.handleFavorite()}
                src={heart}
                alt="heart"
                className="favorite-button"
              />
              <img
                onClick={() => this.handleFavorite()}
                src={heartFilled}
                alt="heart"
                className={this.state.favorite ? 'heart-filled' : 'not-filled'}
              />
            </>
          )}

          <div className="favorite-title">{this.props.data.title}</div>
          {!this.props.data.free && !hasAccess(this.props.userData.subscription_end_date) ? (
            <></>
          ) : (
            <img
              onClick={() =>
                this.props.history.push('/player', {
                  trackData: this.props.data,
                  dashboard: this.state.dashboard,
                  filter: this.state.filter && !this.state.dashboard ? true : false,
                })
              }
              src={play}
              alt="play"
              className="favorite-play"
            />
          )}
        </div>
      </ImageFadeIn>
    );
  }
}

export default withRouter(FavoriteTrack);
