import React, { Component } from 'react';
import './App.scss';
import Routes from '../src/routes';
import Nav from '../src/components/Nav/Nav';
import { withRouter } from 'react-router-dom';
import axios from 'axios';

class App extends Component {
  state = {
    hideNav: true,
    contentIsFree: true
  };

  componentDidMount() {
    this.startRefreshTokenTimer();
  }

  async refreshToken() {
    await axios.get(`/api/auth/refresh-token`);
  }

  startRefreshTokenTimer = async () => {
    this.tokenTm = setInterval(this.refreshToken, 300 * 1000);
  };

  handleNav = (key) => {
    if (key === this.state.hideNav) return;
    this.setState({
      hideNav: key,
    });
  };

  render() {
    return (
      <div className="App">
        <Routes contentIsFree={this.state.contentIsFree} handleNav={this.handleNav} />
        {this.state.hideNav ? <> </> : <Nav />}
      </div>
    );
  }
}

export default withRouter(App);
