import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import logo from '../../assets/nav/logo@3x.png';
import logoText from '../../assets/landing/lotimus.png';
import you from '../../assets/nav/You@3x.png';
import youSelected from '../../assets/nav/youColor@3x.png';
import journeys from '../../assets/nav/Journeys@3x.png';
import journeysSelected from '../../assets/nav/journeysColor@3x.png';
import learn from '../../assets/nav/Learn@3x.png';
import learnSelected from '../../assets/nav/learnColor@3x.png';
import explore from '../../assets/nav/Explore@3x.png';
import exploreSelected from '../../assets/nav/exploreColor@3x.png';
class Nav extends Component {
  state = {
    youClicked: this.props.location.pathname === '/',
    journeysClicked: this.props.location.pathname === '/journeys',
    learnClicked: this.props.location.pathname === '/learn',
    exploreClicked: this.props.location.pathname === '/explore',
  };

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      if (this.props.location.pathname === '/') {
        return this.setState({
          youClicked: true,
          journeysClicked: false,
          learnClicked: false,
          exploreClicked: false,
        });
      } else if (this.props.location.pathname === '/journeys') {
        return this.setState({
          youClicked: false,
          journeysClicked: true,
          learnClicked: false,
          exploreClicked: false,
        });
      } else if (this.props.location.pathname === '/learn') {
        return this.setState({
          youClicked: false,
          journeysClicked: false,
          learnClicked: true,
          exploreClicked: false,
        });
      } else if (this.props.location.pathname === '/explore') {
        return this.setState({
          youClicked: false,
          journeysClicked: false,
          learnClicked: false,
          exploreClicked: true,
        });
      }
    }
  }

  handleClick(key, route) {
    if (this.state[key] && this.props.location.pathname === route) {
      return;
    } else {
      const newState = {
        youClicked: false,
        journeysClicked: false,
        learnClicked: false,
        exploreClicked: false,
      };

      newState[key] = true;
      this.setState(newState, () => this.props.history.push(route));
    }
  }

  render() {
    return (
      <div className="nav-container">
        <div
          onClick={() =>
            this.props.location.pathname !== '/' ? this.props.history.push('/') : <></>
          }
          className="logo"
        >
          <img src={logo} alt="logo" className="logo-image" />
          <img src={logoText} alt="logo-text" className="logo-text" />
        </div>
        <div
          onClick={() => this.handleClick('youClicked', '/')}
          className={this.state.youClicked ? 'container-clicked y' : 'you-container-i'}
        >
          <img src={you} alt="journeys" className="journeys" />
          <img
            src={youSelected}
            alt="journeys"
            className={this.state.youClicked ? 'youI selected' : 'youI not-selected'}
          />
          <span className="you-title">YOU</span>
        </div>
        <div
          onClick={() => this.handleClick('journeysClicked', '/journeys')}
          className={this.state.journeysClicked ? 'container-clicked j' : 'journeys-container-i'}
        >
          <img src={journeys} alt="journeys" className="journeys" />
          <img
            src={journeysSelected}
            alt="journeys"
            className={this.state.journeysClicked ? 'journeysI selected' : 'journeysI not-selected'}
          />
          <span className="journeys-title">JOURNEYS</span>
        </div>
        <div
          onClick={() => this.handleClick('exploreClicked', '/explore')}
          className={this.state.exploreClicked ? 'container-clicked e' : 'explore-container-i'}
        >
          <img src={explore} alt="journeys" className="journeys" />
          <img
            src={exploreSelected}
            alt="journeys"
            className={this.state.exploreClicked ? 'exploreI selected' : 'exploreI not-selected'}
          />
          <span className="explore-title">EXPLORE</span>
        </div>
        <div
          onClick={() => this.handleClick('learnClicked', '/learn')}
          className={this.state.learnClicked ? 'container-clicked l' : 'learn-container-i'}
        >
          <img src={learn} alt="journeys" className="journeys" />
          <img
            src={learnSelected}
            alt="journeys"
            className={this.state.learnClicked ? 'learnI selected' : 'learnI not-selected'}
          />
          <span className="learn-title">LEARN</span>
        </div>
      </div>
    );
  }
}

export default withRouter(Nav);
