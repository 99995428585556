import React, { Component } from 'react';
import axios from 'axios';
import Track from '../Track/Track';
import backArrow from '../../assets/journeys/backarrow@2x.png';
import SubscribeModal from '../SubscribeModal/SubscribeModal';

import { BounceLoader } from 'react-spinners';
import { css } from '@emotion/core';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

class TracksList extends Component {
  state = {
    tracks: [],
    loading: true,
    lockModal: false,
  };

  componentDidMount() {
    this.props.handleNav(false);
    axios.get(`/api/media/tracks/${this.props.location.state.category.id}`).then((res) => {
      this.setState({ tracks: res.data, loading: false });
    });

    document.addEventListener('keydown', this.handleKeyDown);
  }

  handleKeyDown = (event) => {
    if (event.keyCode === 27 && this.state.lockModal) {
      this.setState({ lockModal: false });
    }
  };

  handleLock = (type) => {
    if (type === 'unlocked') {
      return;
    }
    this.setState({ lockModal: true });
  };

  handleCancel = () => {
    this.setState({ lockModal: false });
  };

  render() {
    const tracks = this.state.tracks.map((el, i) => (
      <Track handleLock={this.handleLock} userData={this.props.userData} key={i} data={el} />
    ));
    return (
      <div className="tracks-list-container">
        {!this.state.loading ? (
          <>
            <nav onClick={() => this.props.history.goBack()}>
              <img src={backArrow} alt="back" className="back-arrow" />
              MORE JOURNEYS
            </nav>
            {tracks}
            <div className="buffer"></div>
            <div className={this.state.lockModal ? 'dark-bg-settings visible' : 'dark-bg-settings'}>
              <SubscribeModal handleCancel={this.handleCancel} />
            </div>
          </>
        ) : (
          <div className="loading-results">
            <BounceLoader
              color={'#a89cd9'}
              css={override}
              sizeUnit={'px'}
              size={150}
              loading={this.state.loading}
            />
          </div>
        )}
      </div>
    );
  }
}

export default TracksList;
