import React, { Component } from 'react';
import playButton from '../../assets/journeys/playbutton@2x.png';
import pauseButton from '../../assets/player/pauseButton.png';
import introImg from '../../assets/landing/introimg.png';
import Loader from 'react-loader-spinner';
import ImageFadeIn from 'react-image-fade-in';

class BonusTrack extends Component {
  state = {
    playing: false,
    commentary: false,
    animate: false,
    duration: 0,
  };
  audioEl = {};
  commentaryEl = {};

  componentDidMount() {
    this.audioEl = document.getElementById(`bonus-track-audio-${this.props.data.id}`);
    this.commentaryEl = document.getElementById(`bonus-track-commentary-${this.props.data.id}`);

    this.audioEl.onended = () => {
      this.setState({ playing: false });
    };

    this.audioEl.onloadedmetadata = () => {
      this.setState({ duration: this.formatTime(this.audioEl.duration) });
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.shouldPause !== this.props.shouldPause) {
      if (this.props.shouldPause) {
        this.setState({ playing: false, commentary: false, animate: false }, () => {
          this.audioEl.pause();
          this.commentaryEl.pause();
        });
      }
    }

    if (prevProps.bonusId !== this.props.bonusId) {
      if (this.props.bonusId !== this.props.data.id) {
        this.setState({ playing: false, commentary: false, animate: false }, () => {
          this.audioEl.pause();
          this.commentaryEl.pause();
        });
      }
    }
  }

  formatTime(timeInSeconds) {
    if (timeInSeconds === 0) {
      return `${timeInSeconds} MIN`;
    }
    return `${Math.floor(timeInSeconds / 60)} MIN`;
  }

  playToggle() {
    if (!this.state.playing) {
      if (this.state.commentary) {
        this.setState({ commentary: false, animate: false, playing: true }, () => {
          this.commentaryEl.pause();
          this.props.handlePause(this.props.data.id);
          this.audioEl.play();
        });
      } else {
        this.setState({ playing: true }, () => {
          this.props.handlePause(this.props.data.id);
          this.audioEl.play();
        });
      }
    } else {
      this.setState({ playing: false }, () => {
        this.audioEl.pause();
      });
    }
  }

  handleCommentary() {
    this.commentaryEl.onended = () => {
      this.setState({ commentary: false, animate: false });
    };

    if (!this.state.commentary) {
      if (this.state.playing) {
        this.setState({ animate: true, playing: false, commentary: true }, () => {
          this.audioEl.pause();
          this.props.handlePause(this.props.data.id);
          this.commentaryEl.play();
        });
      } else {
        this.setState({ animate: true, commentary: true }, () => {
          this.props.handlePause(this.props.data.id);
          this.commentaryEl.play();
        });
      }
    } else if (this.state.commentary) {
      this.setState({ animate: false, commentary: false }, () => {
        this.commentaryEl.pause();
      });
    }
  }

  render() {
    const { data } = this.props;
    return (
      <div className="bonus-track-outer">
        <div className="bonus-track">
          <audio preload="metadata" id={`bonus-track-audio-${data.id}`} src={data.url} />
          <div className="exercise-title">{data.title}</div>
          <span>{this.state.duration}</span>
          <ImageFadeIn
            onClick={() => this.playToggle()}
            src={this.state.playing ? pauseButton : playButton}
            alt="play-button"
          />
        </div>
        <div onClick={() => this.handleCommentary()} className="commentary">
          {this.state.animate ? (
            <Loader type="Audio" color="#a89cd9" height={20} width={25} />
          ) : (
            <ImageFadeIn src={introImg} alt="intro" />
          )}
          <span>Commentary</span>
          <audio
            preload="none"
            id={`bonus-track-commentary-${data.id}`}
            src={data.commentary_url}
          />
        </div>
      </div>
    );
  }
}

export default BonusTrack;
