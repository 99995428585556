import React, { Component } from 'react';
import logo from '../../assets/nav/logo@3x.png';
import logoText from '../../assets/landing/lotimus.png';
import headphones from '../../assets/landing/headphones.png';
import introAudio from '../../assets/landing/intro.mp3';
import introImg from '../../assets/landing/introimg.png';
import phones from '../../assets/landing/phones.png';
import Loader from 'react-loader-spinner';
import eye from '../../assets/landing/eye.svg';
import downArrow from '../../assets/landing/downArrow.svg';
import AddToHomeScreenModal from '../AddToHomescreenModal/AddToHomescreenModal';
import ForgotPassword from '../ForgotPassword/ForgotPassword';
import dismiss from '../../assets/you/x.svg';
import cookie from 'js-cookie';
import { checkPassword, validateEmail } from '../../common/validation';
import axios from 'axios';

class LoggedOutLanding extends Component {
  state = {
    email: '',
    password: '',
    passConfirm: '',
    showConfirm: false,
    passMatch: true,
    modal: false,
    addToHomescreenModal: false,
    play: false,
    about: this.props.location.state && this.props.location.state.about ? true : false,
    emailInvalid: false,
    passInvalid: false,
    userExists: false,
    incorrectPassEmail: false,
    showPass: false,
    forgot: false,
    install: false,
    showMessage: false,
    age: null,
    gender: null,
    groupCode: null
  };

  audioEl = {};

  componentDidMount() {
    this.props.handleNav(true);
    this.audioEl = document.getElementById('audio-element');
    document.addEventListener('keydown', this.handleKeyDown);

    window.addEventListener('beforeinstallprompt', (event) => {
      window.deferredPrompt = event;

      this.setState({ install: true });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.modal !== this.state.modal) {
      if (this.state.modal) {
        window.addEventListener('click', this.clickEvent);
      }
    }
  }

  handleKeyDown = (event) => {
    if (event.keyCode === 27 && this.state.modal) {
      this.setState({ modal: false, forgot: false });
      window.removeEventListener('click', this.clickEvent);
    }
  };

  handleEnter(key) {
    if (key === 13) {
      if (this.state.showConfirm) {
        this.register();
      } else {
        this.login();
      }
    }
  }

  handleStart = (event) => {
    event.stopPropagation();
    this.setState({ modal: true });
    if (!this.state.forgot) {
      document.getElementById('email-input').focus();
    }
  };

  async login() {
    if (this.state.showConfirm) {
      return this.setState({
        showConfirm: false,
        passConfirm: '',
        userExists: false,
        passMatch: true,
        passInvalid: false,
      });
    }

    this.setState({ userExists: false, emailInvalid: false, passInvalid: false });
    await axios
      .post('/api/auth/login', {
        email: this.state.email.toLowerCase().trim(),
        password: this.state.password,
      })
      .catch((err) => {
        if (err.response.status === 401) {
          this.setState({ incorrectPassEmail: true });
        }
      });

    if (cookie.get('token')) {
      this.props.history.push('/');
    }
  }

  async register() {
    this.setState({
      incorrectPassEmail: false,
      passMatch: true,
      passInvalid: false,
      showMessage: false
    });

    if (!this.state.passConfirm && !this.state.showConfirm) {
      return this.setState({ showConfirm: true });
    }

    if (!this.state.gender || !this.state.age) {
      return this.setState({showMessage: true})
    }

    let emailValid = false;
    let passValid = false;

    if (validateEmail(this.state.email)) {
      emailValid = true;

      this.setState({ emailInvalid: false });
    } else {
      this.setState({ emailInvalid: true });
    }

    if (checkPassword(this.state.password)) {
      passValid = true;
      this.setState({ passInvalid: false });
    } else {
      this.setState({ passInvalid: true });
    }

    if (this.state.password !== this.state.passConfirm) {
      return this.setState({ passMatch: false });
    }

    if (emailValid && passValid) {
      await axios
        .post('/api/auth/register', {
          email: this.state.email.toLowerCase().trim(),
          password: this.state.password,
          age: this.state.age,
          gender: this.state.gender,
          groupCode: this.state.groupCode
        })
        .catch((err) => {
          if (err.response.status === 409) {
            this.setState({ userExists: true });
          }
        });

      if (cookie.get('token')) {
        this.props.history.push('/', {
          registered: true,
        });
      }
    }
  }

  handleIntro() {
    this.audioEl.onended = () => {
      this.setState({ play: false });
    };

    this.setState({ play: !this.state.play }, () => {
      this.state.play ? this.audioEl.play() : this.audioEl.pause();
    });
  }

  handleAbout = () => {
    this.props.history.push('/home', { about: true });
    this.setState({ about: true });
    window.scrollTo(0, 0);
  };

  handleInstall = () => {
    if (this.state.install) {
      window.deferredPrompt.prompt();
    } else {
      this.setState({ addToHomescreenModal: true });
    }
  };

  setForgot = () => {
    this.setState({ forgot: false });
  };

  handleDropDowns = ({ target: { name, value } }) => {
    this.setState({ [name.toLowerCase()]: value === 'null' ? null : value })
}

  render() {
    return (
      <div className="logged-out-landing-container">
        {this.state.addToHomescreenModal ? (
          <AddToHomeScreenModal
            handleAddToHomescreenModal={() => this.setState({ addToHomescreenModal: false })}
          />
        ) : (
          <></>
        )}
        <nav>
          <div className="logo">
            <img
              onClick={() => this.setState({ about: false })}
              src={logoText}
              alt="Lotimus"
              className="logo-image"
            />
          </div>
          <div className="nav-right">
            <span onClick={() => this.handleAbout()} className="about">
              About
            </span>
            <span onClick={() => this.handleAbout()} className="lotimus">
              Lotimus
            </span>
            {this.props.userData && this.props.userData.id ? (
              <button onClick={() => this.props.history.push('/')} className="signup">
                Dashboard
              </button>
            ) : (
              <button onClick={this.handleStart} className="signup">
                Login | Register
              </button>
            )}
          </div>
        </nav>
        <main>
          {!this.state.about ? (
            <>
              <div className="hero">
                <span className="never">You've never </span>
                <span className="meditated">meditated </span>
                <span className="before">like this before</span>
                <div className="hero-break"></div>
                <h5>Take a journey and retrain your brain</h5>
                <div className="hero-break"></div>
                <button
                  onClick={
                    this.props.userData && this.props.userData.id
                      ? () => this.props.history.push('/journeys')
                      : this.handleStart
                  }
                  className="bottom-start"
                >
                  Start listening
                </button>
                <img
                  src={downArrow}
                  onClick={() =>
                    window.scrollTo({
                      top: 670,
                      behavior: 'smooth',
                    })
                  }
                  alt="down-arrow"
                  className="down-arrow"
                />
              </div>
              <div className="hero2">
                <main className="main">
                  <div className="hero-2-text-top">
                    <span>
                      Lotimus is a unique new way to meditate. By combining classic story telling
                      and hypnotic soundscapes, we take you on first-person journeys through magical
                      worlds, helping you
                    </span>
                    <span className="unlock"> unlock your brain’s potential to heal and grow.</span>
                    <div className="break"></div>
                    <span className="learn-more">Learn more about why Lotimus was created</span>
                    <span onClick={() => this.handleAbout()} className="here">
                      here.
                    </span>
                  </div>
                  <div className="headphones-container">
                    <img
                      onClick={() => this.handleIntro()}
                      src={headphones}
                      alt="headphones"
                      className="headphones"
                    />
                    <div onClick={() => this.handleIntro()} className="intro">
                      <audio preload="auto" id="audio-element" src={introAudio} />
                      {this.state.play ? (
                        <Loader type="Audio" color="#a89cd9" height={40} width={50} />
                      ) : (
                        <img src={introImg} alt="intro" />
                      )}
                      <span>LISTEN TO INTRO</span>
                      <span className="intro-length">2 min</span>
                    </div>
                  </div>
                </main>
              </div>
              <div className="hero3">
                <img src={phones} alt="phones" className="phones" />
                <div className="phones-text">
                  <span>Add Lotimus to your home screen</span>
                  <button onClick={this.handleInstall} className="shortcut">
                    Add shortcut now
                  </button>
                </div>
              </div>
            </>
          ) : (
            <div className="about-container">
              <p className="about-title">One evening, a few years ago…</p>
              <p>
                Lotimus founder and longtime meditation practitioner Becky Davidson found herself
                face to face with a sixteen-year-old daughter paralyzed by anxiety. Instinctively,
                she knew that meditation would help, but she knew also that the kind of monastic
                practice she herself had taken up wouldn’t necessarily serve a girl on the brink of
                unraveling. So, she decided to try something different, something with a plot arc.
                Summoning her best storytelling voice, she started into a narrative meditation, a
                hero’s journey story that featured her daughter on a quest for healing.
              </p>
              <p className="about-change">
                What followed was an experience that changed them both.
              </p>
              <p>
                “It was this really unusual, hybrid thing: a first-person story and a meditation,”
                Davidson recalls. “My daughter closed her eyes and assumed a relaxed, meditative
                posture, and I improvised this wild narrative. As the story went, I led her out into
                a lonely meadow. Stars wheeled across the night sky. Meadow grasses stirred in the
                wind. I told her that in the center of the meadow huddled a tiny, broken creature,
                her knees pulled up to her chest, her face to the ground. When my daughter knelt
                down to investigate, she noticed that the creature’s wings had sheared off, leaving
                twin wounds on her back. A fairy, unable to fly. As my girl reached out and laid her
                hands on the fairy’s back, a perfect set of wings sprouted once more from the
                fairy’s shoulder blades, opening, billowing, lifting her into the air. Then the
                fairy vanished, a set of gorgeous wings sprouted from my daughter’s own back, and
                SHE became the fairy, renewed and full of power.”
              </p>
              <p>
                According to Davidson, it seemed hollow to promise her daughter that everything
                would turn out fine. “She felt what she felt,” Davidson says, “and I wanted to honor
                that. But I also wanted her to experience that rush of healing power. And to imagine
                the feel of those wings sprouting from her own shoulder blades. So I cast her as a
                journeyer, and I set her down in a mindscape where she could phase from victim to
                healer. Somehow, that meditative story became a re-story, unlocking a resilience
                that dissolved anxiety and fear.”
              </p>
              <p className="about-change">
                Out of that unique moment came the concept for the Mind Journey.
              </p>
              <p>
                Equal parts narrative and meditation, each Lotimus mind journey carries listeners
                into exotic worlds where they imagine setting off on quests, working around
                obstacles, and capturing the simple but transformative wisdom at the journey’s end.
                These are hero’s journeys—big on imagination, but also big on wisdom. “I want to set
                people on a path inward,” Davidson says, “where they can discover their own ability
                to heal and transform. The body goes still. The triggering thoughts go still. But
                the imagination lights up, allowing people to access places inside themselves which
                are often inaccessible due to the manic pace of their lives and their thoughts.”
              </p>
              <p>
                With the sound talents of Dane Holmes and Caleb Loveless of Slowave Studios, mind
                journeys have become lush, immersive experiences that take listeners deep into their
                own vivid imaginings.
              </p>
              <p className="about-journey">Are you ready to journey?</p>
              <button
                onClick={
                  this.props.userData && this.props.userData.id
                    ? () => this.props.history.push('/journeys')
                    : this.handleStart
                }
                className="bottom-start"
              >
                Start listening
              </button>
            </div>
          )}
          <div className="footer">
            <a href="mailto:support@lotimus.com">Contact us</a>
            <div className="logo">
              <div
                onClick={() => {
                  this.setState({ about: false });
                  window.scrollTo({ top: 0, behavior: this.state.about ? 'auto' : 'smooth' });
                }}
                className="image"
              >
                <img src={logo} alt="logo" />
                <img src={logoText} alt="lotimus" className="logo-text" />
              </div>
              <span>Copyright 2020</span>
            </div>
          </div>
        </main>
        <div className={this.state.modal ? 'dark-bg-landing visible' : 'dark-bg-landing'}>
          <div id="sign-up-modal" className="sign-up-modal">
            {!this.state.forgot ? (
              <>
                <img
                  onClick={() =>
                    this.setState({
                      modal: false,
                      showConfirm: false,
                      passMatch: true,
                      incorrectPassEmail: false,
                      userExists: false,
                      password: '',
                      passConfirm: '',
                      email: '',
                    })
                  }
                  src={dismiss}
                  alt="dismiss"
                  className="dismiss-sign-up"
                />
                <span className="welcome">Welcome to Lotimus</span>
                <div className="email">
                  <span>Email</span>
                  <input
                    id="email-input"
                    value={this.state.email}
                    onChange={(e) => this.setState({ email: e.target.value })}
                    type="text"
                    spellCheck="false"
                    className="username"
                  />
                  {this.state.emailInvalid ||
                  this.state.userExists ||
                  this.state.incorrectPassEmail ? (
                    this.state.emailInvalid ? (
                      <span className="email-invalid">* Please enter a valid email address</span>
                    ) : this.state.userExists ? (
                      <span className="email-invalid">* User Already Exists</span>
                    ) : this.state.incorrectPassEmail ? (
                      <span className="email-invalid">* Incorrect Email or Password</span>
                    ) : (
                      <></>
                    )
                  ) : (
                    <></>
                  )}
                </div>
                <div className="password">
                  <span>Password</span>
                  <input
                    onKeyDown={(e) => this.handleEnter(e.keyCode)}
                    value={this.state.password}
                    onChange={(e) => this.setState({ password: e.target.value })}
                    type={this.state.showPass ? 'text' : 'password'}
                    className="password"
                  />
                  <img
                    onClick={() => this.setState({ showPass: !this.state.showPass })}
                    className="eyeball"
                    alt="password-reveal"
                    src={eye}
                  />
                </div>
                <div className={this.state.showConfirm ? 'password show' : 'password hide'}>
                  <span>Confirm Password</span>
                  <input
                    onKeyDown={(e) => this.handleEnter(e.keyCode)}
                    value={this.state.passConfirm}
                    onChange={(e) => this.setState({ passConfirm: e.target.value })}
                    type={this.state.showPass ? 'text' : 'password'}
                    className="password"
                  />
                  {this.state.passInvalid || !this.state.passMatch ? (
                    this.state.passInvalid ? (
                      <span className="pass-invalid">
                        *Password must have 6-20 characters with a number, an upper and a lowercase
                        letter
                      </span>
                    ) : !this.state.passMatch ? (
                      <span className="pass-invalid">*Passwords do not match</span>
                    ) : (
                      <></>
                    )
                  ) : (
                    <></>
                  )}
                  <span>Age</span>
                  <select onChange={this.handleDropDowns} className='password' name="Age" id="Age">
                    <option value="null">Select an Option</option>
                    <option value="18 - 25">18 - 25</option>
                    <option value="26 - 35">26 - 35</option>
                    <option value="36 - 45">36 - 45</option>
                    <option value="46 - 60">46 - 60</option>
                    <option value="60+">60+</option>
                  </select>
                  <span>Gender</span>
                  <select onChange={this.handleDropDowns} className='password' name="Gender" id="Gender">
                    <option value="null">Select an Option</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                    <option value="Don't Wish to Say">Don't Wish to Say</option>
                  </select>
                  <span>Group Code (optional)</span>
                  <input
                    onKeyDown={(e) => this.handleEnter(e.keyCode)}
                    value={this.state.coupon}
                    onChange={(e) => this.setState({ groupCode: e.target.value })}
                    type= 'text'
                    spellCheck="false"
                    className="username"
                  />
                </div>
                {this.state.showMessage ? (
                  <span style={{color: 'red'}}>*Please fill out the required inputs</span>
                  ) : (
                    <></>
                  )}
                <div className="buttons">
                  <button onClick={() => this.login()} className="login">
                    Login
                  </button>
                  <button onClick={() => this.register()} className="create">
                    Create new account
                  </button>
                </div>
                <div onClick={() => this.setState({ forgot: true })} className="forgot">
                  Forgot password?
                </div>
              </>
            ) : (
              <ForgotPassword back={this.setForgot} />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default LoggedOutLanding;
