import React, { Component } from 'react';
import { CSVLink } from 'react-csv';
import axios from 'axios';
import { BounceLoader } from 'react-spinners';
import { css } from '@emotion/core';
import { withRouter } from 'react-router-dom';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

class AdminUserList extends Component {
  state = {
    getAllUsers: false,
    getSubscribedUsers: false,
    getFreeUsers: false,
    users: [],
    generated: false,
    date: new Date(Date.now()).toLocaleDateString(),
  };

  handleChoice(type) {
    if (type === 'all') {
      this.setState({ getAllUsers: true, getSubscribedUsers: false, getFreeUsers: false });
    } else if (type === 'subbed') {
      this.setState({ getAllUsers: false, getSubscribedUsers: true, getFreeUsers: false });
    } else if (type === 'free') {
      this.setState({ getAllUsers: false, getSubscribedUsers: false, getFreeUsers: true });
    }
  }

  handleSubmit() {
    if (!this.state.getFreeUsers && !this.state.getSubscribedUsers && !this.state.getAllUsers) {
      return window.alert('Please choose an option');
    }

    const submitButton = document.getElementById('submit');
    submitButton.disabled = true;

    this.setState({ loading: true, generated: false });

    axios
      .get(
        `/api/admin/user-list/${
          this.state.getAllUsers
            ? 'all'
            : this.state.getSubscribedUsers
            ? 'subbed'
            : this.state.getFreeUsers
            ? 'free'
            : ''
        }`,
      )
      .then((res) => {
        if (res.status === 200) {
          if (res.data !== 'no users found') {
            this.setState(
              {
                loading: false,
                users: res.data,
              },
              () => {
                submitButton.disabled = false;
              },
            );
          } else {
            submitButton.disabled = false;
            this.setState({
              generated: true,
              loading: false,
              getAllUsers: false,
              getFreeUsers: false,
              getSubscribedUsers: false,
              users: [],
            });
          }
        } else {
          window.alert('unknown error occurred');
          submitButton.disabled = false;
          this.setState({
            loading: false,
            getAllUsers: false,
            getFreeUsers: false,
            getSubscribedUsers: false,
            users: [],
          });
        }
      })
      .catch((err) => this.props.history.push('/home'));
  }

  render() {
    return (
      <div className="admin-user-list-container">
        <h3>Generate List of Users</h3>

        <div className="choice-container">
          <span
            className={this.state.getAllUsers ? 'selected-user-type choice' : 'choice'}
            onClick={() => this.handleChoice('all')}
          >
            All Users
          </span>
          <span
            className={this.state.getSubscribedUsers ? 'selected-user-type choice' : 'choice'}
            onClick={() => this.handleChoice('subbed')}
          >
            Subscribed Users
          </span>
          <span
            className={this.state.getFreeUsers ? 'selected-user-type choice' : 'choice'}
            onClick={() => this.handleChoice('free')}
          >
            Free Users
          </span>
        </div>

        <button onClick={() => this.handleSubmit()} id="submit" className="submit">
          Generate
        </button>
        {this.state.loading ? (
          <div className="loading-results-admin">
            <BounceLoader
              color={'#a89cd9'}
              css={override}
              sizeUnit={'px'}
              size={75}
              loading={this.state.loading}
            />
          </div>
        ) : (
          <></>
        )}
        {this.state.users.length > 0 ? (
          <div className="download-user">
            <h4>{`${this.state.users.length} ${
              this.state.users.length > 1 ? 'users' : 'user'
            }`}</h4>
            <CSVLink
              filename={
                this.state.getAllUsers
                  ? `All users - ${this.state.date}.csv`
                  : this.state.getSubscribedUsers
                  ? `Subscribed users - ${this.state.date}.csv`
                  : this.state.getFreeUsers
                  ? `Free users - ${this.state.date}.csv`
                  : ''
              }
              className="csv"
              data={this.state.users}
            >
              Download CSV
            </CSVLink>
          </div>
        ) : this.state.generated ? (
          <span>No users found</span>
        ) : (
          <></>
        )}
      </div>
    );
  }
}

export default withRouter(AdminUserList);
