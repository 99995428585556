import React from 'react';

const Subscribe = (props) => {
  props.handleNav(false);
  return (
    <div className="subscribe-container">
      <div className="sub year">
        <span className="sub-title">12 months</span>
        <span className="sub-subtitle">of unlimited usage</span>
        <div className="savings">
          <div className="left"></div>
          <div className="left2"></div>
          <div className="middle">
            <span>Save 18%</span>
          </div>
          <div className="right"></div>
          <div className="right2"></div>
        </div>
        <h1>Start Today</h1>
        <span>$78.95 billed annually ($6.58/mo)</span>
        <br />
        <button onClick={() => props.history.push('/payment', { plan: 'yearly' })}>
          Get Started
        </button>
      </div>
      <div className="sub month">
        <span className="sub-title">1 month</span>
        <span className="sub-subtitle">of unlimited usage</span>
        <div className="savings break"></div>
        <h1>Start Today</h1>
        <span>$7.95 billed monthly</span>
        <br />
        <button onClick={() => props.history.push('/payment', { plan: 'monthly' })}>
          Get Started
        </button>
      </div>
    </div>
  );
};

export default Subscribe;
