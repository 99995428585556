const validateEmail = (mail) => {
  // eslint-disable-next-line no-useless-escape
  return /^\w+([\.+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail);
};

const checkPassword = (inputPass) => {
  let password = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
  return password.test(inputPass);
};

const hasAccess = (endDate) => {
  return endDate && new Date(endDate) > new Date() ? true : false;
};

const isIos = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /iphone|ipad|ipod/.test(userAgent);
};

module.exports = {
  validateEmail,
  checkPassword,
  hasAccess,
  isIos
};
