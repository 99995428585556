import React, { Component } from 'react';
import { Circle } from 'rc-progress';
import BonusTrack from '../../components/BonusTrack/BonusTrack';
import backArrow from '../../assets/journeys/backarrow@2x.png';
import play from '../../assets/player/play.png';
import pause from '../../assets/player/pause.png';
import back from '../../assets/player/back.png';
import forward from '../../assets/player/forward.png';
import sound from '../../assets/player/sound.png';
import music from '../../assets/player/music.png';
import check from '../../assets/player/check.png';
import next from '../../assets/player/next.png';
import SubscribeModal from '../SubscribeModal/SubscribeModal';
import restart from '../../assets/player/restart.svg';
import { hasAccess } from '../../common/validation';
import axios from 'axios';
import ImageFadeIn from 'react-image-fade-in';

class Player extends Component {
  state = {
    percent: 0,
    playing: false,
    audio: true,
    music: false,
    keyboard: true,
    length: 0,
    bonusTracks: [],
    bonusTitle: '',
    listened: false,
    imageUrl: this.props.location.state.trackData.image_url,
    trackId: this.props.location.state.trackData.id,
    catTitle: this.props.location.state.trackData.cat_title,
    categoryId: this.props.location.state.trackData.category_id,
    trackUrl: `/api/media/audio/${this.props.location.state.trackData.id}`,
    musicUrl: `/api/media/music/${this.props.location.state.trackData.id}`,
    trackTitle: this.props.location.state.trackData.title,
    trackDescription: this.props.location.state.trackData.description,
    nextImageUrl: '',
    nextTrackId: '',
    nextTrackUrl: '',
    nextMusicUrl: '',
    nextTrackTitle: '',
    nextTrackDescription: '',
    next: this.props.location.state.filter ? false : true,
    filter: this.props.location.state.filter ? true : false,
    dashboard: this.props.location.state.dashboard ? true : false,
    modal: false,
    bonusShouldPause: false,
    bonusId: null,
  };
  audioEl = {};
  musicEl = {};
  fullAudioEl = {};

  async componentDidMount() {
    this.props.handleNav(false);

    window.addEventListener('beforeunload', this.beforeUnload);

    const container = document.getElementById('player');
    container.style.setProperty('--background-url', `url('${this.state.imageUrl}')`);

    this.setAudioElements();

    this.audioEl.onloadedmetadata = () => {
      this.setState({ length: this.audioEl.duration });
      this.setProgress();
    };

    axios
      .get(`/api/media/user-track?userId=${this.props.userData.id}&trackId=${this.state.trackId}`)
      .then((userTrack) => {
        let progress;
        if (userTrack && userTrack.data && userTrack.data[0]) {
          progress = userTrack.data[0].progress;
        } else {
          progress = 0.01;
        }

        this.audioEl.currentTime = progress;
        this.setProgress();
      });

    this.audioEl.onended = this.handleEnd;

    this.musicEl.onended = this.handleEnd;

    document.addEventListener('keydown', this.handleKeyDown);
    document.addEventListener('keydown', this.handleKeyDownModal);

    axios.get(`/api/media/bonus-tracks/${this.state.trackId}`).then((res) =>
      this.setState({ bonusTracks: res.data }, () => {
        if (this.state.bonusTracks.length > 0) {
          this.setState({ bonusTitle: 'More stories from this world' });
        }
      }),
    );

    if (!this.props.location.state.filter) {
      this.getNextTrack();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.trackId !== this.state.trackId) {
      this.getNextTrack();

      const container = document.getElementById('player');
      container.style.setProperty('--background-url', `url('${this.state.imageUrl}')`);

      axios.get(`/api/media/bonus-tracks/${this.state.trackId}`).then((res) =>
        this.setState({ bonusTracks: res.data }, () => {
          if (this.state.bonusTracks.length > 0) {
            this.setState({ bonusTitle: 'More stories from this world' });
          }
        }),
      );
    }
  }

  componentWillUnmount() {
    clearInterval(this.dbTm);
    clearInterval(this.tm);
    window.removeEventListener('beforeunload', this.beforeUnload);
    window.removeEventListener('keydown', this.handleKeyDown);
    window.removeEventListener('keydown', this.handleKeyDownModal);
  }

  setAudioElements = () => {
    this.fullAudioEl = document.getElementById('track-audio');
    this.audioEl = this.fullAudioEl;
    this.musicEl = document.getElementById('music-audio');
  };

  getNextTrack = () => {
    axios
      .get(`/api/media/track?catId=${this.state.categoryId}&trackId=${this.state.trackId}`)
      .then((res) => {
        if (res.data[0] && (hasAccess(this.props.userData.subscription_end_date) || res.data[0].free)) {
          this.setState({
            nextImageUrl: res.data[0].image_url,
            nextTrackId: res.data[0].id,
            nextTrackUrl: `/api/media/audio/${res.data[0].id}`,
            nextMusicUrl: `/api/media/music/${res.data[0].id}`,
            nextTrackTitle: res.data[0].title,
            nextTrackDescription: res.data[0].description,
            nextTrackIsFree: res.data[0].free
          });
        } else if (res.data[0] && (!hasAccess(this.props.userData.subscription_end_date && !res.data[0].free))) {
          this.setState({
            nextImageUrl: res.data[0].image_url,
            nextTrackId: res.data[0].id,
            nextTrackTitle: res.data[0].title,
            nextTrackDescription: res.data[0].description,
            nextTrackIsFree: res.data[0].free
          });
        } else {
          this.setState({ next: false });
        }
      });
  };

  beforeUnload = (event) => {
    this.props.history.push('/player', {
      trackData: {
        image_url: this.state.imageUrl,
        id: this.state.trackId,
        cat_title: this.state.catTitle,
        category_id: this.state.categoryId,
        url: this.state.trackUrl,
        music_url: this.state.musicUrl,
        title: this.state.trackTitle,
        description: this.state.trackDescription,
      },
      filter: this.state.filter,
      dashboard: this.state.dashboard,
    });
  };

  handleEnd = async () => {
    clearInterval(this.tm);
    clearInterval(this.dbTm);

    this.setState({ playing: false, listened: true, keyboard: false });
    this.audioEl.currentTime = 0.01;

    await axios.put(
      `/api/media/recent/?userId=${this.props.userData.id}&trackId=${this.state.trackId}`,
    );

    await axios.post(
      `/api/media/progress?userId=${this.props.userData.id}&trackId=${this.state.trackId}`,
      {
        seconds: 0.01,
      },
    );

    await axios.put(
      `/api/media/count?userId=${this.props.userData.id}&trackId=${this.state.trackId}`,
    );
  };

  setProgress = () => {
    const newPercent = (this.audioEl.currentTime / this.audioEl.duration) * 100;
    this.setState({ percent: newPercent });
  };

  startProgressTimer = () => {
    this.tm = setInterval(this.setProgress, 200);
  };

  setDbProgress = async () => {
    await axios.post(
      `/api/media/progress?userId=${this.props.userData.id}&trackId=${this.state.trackId}`,
      {
        seconds: this.audioEl.currentTime,
      },
    );
  };

  startDbProgressTimer = async () => {
    this.dbTm = setInterval(this.setDbProgress, 30000);
  };

  playToggle() {
    if (!this.state.playing) {
      this.setState({ playing: true, bonusShouldPause: true }, () => {
        this.audioEl.play();
        this.startProgressTimer();
        this.startDbProgressTimer();
      });
    } else {
      this.setState({ playing: false, bonusShouldPause: false }, () => {
        this.audioEl.pause();
        clearInterval(this.tm);
        clearInterval(this.dbTm);
      });
    }
  }

  handleKeyDown = (event) => {
    if (this.state.keyboard) {
      if (event.keyCode === 32 || event.keyCode === 75) {
        this.playToggle();
      } else if (event.keyCode === 74 || event.keyCode === 37) {
        this.handleSeek('back');
      } else if (event.keyCode === 76 || event.keyCode === 39) {
        this.handleSeek();
      }
    }
  };

  handleSeek(direction) {
    direction === 'back' ? (this.audioEl.currentTime -= 15) : (this.audioEl.currentTime += 30);
    if (!this.state.playing) {
      this.setProgress();
    }
  }

  formatTime(timeInSeconds, type) {
    if (type === 'length') {
      if (timeInSeconds === 0) {
        return `${timeInSeconds} MIN`;
      }
      return `${Math.floor(timeInSeconds / 60)} MIN`;
    }
    const pad = function (num, size) {
        return ('000' + num).slice(size * -1);
      },
      time = parseFloat(timeInSeconds).toFixed(3),
      minutes = Math.floor(time / 60) % 60,
      seconds = Math.floor(time - minutes * 60);

    return pad(minutes, 2) + ':' + pad(seconds, 2);
  }

  handleToggle(type) {
    if (type === 'music') {
      if (!this.state.music) {
        this.setState({ audio: false, music: true }, () => {
          this.audioEl.pause();
          this.musicEl.currentTime = this.audioEl.currentTime;
          this.audioEl = this.musicEl;
          if (this.state.playing) this.audioEl.play();
        });
      }
    } else if (type === 'audio') {
      if (!this.state.audio) {
        this.setState({ audio: true, music: false }, () => {
          this.audioEl.pause();
          this.fullAudioEl.currentTime = this.audioEl.currentTime;
          this.audioEl = this.fullAudioEl;
          if (this.state.playing) this.audioEl.play();
        });
      }
    }
  }

  isVowel(str) {
    const vowels = ['a', 'e', 'i', 'o', 'u'];
    let isVowel = false;

    for (let i = 0; i < vowels.length; i++) {
      if (vowels[i] === str[0]) {
        isVowel = true;
      }
    }

    return isVowel;
  }

  handleKeyDownModal = (event) => {
    if (event.keyCode === 27 && this.state.modal) {
      this.setState({ modal: false });
    }
  };

  handleCancel = () => {
    this.setState({ modal: false });
  };

  handleRestart = () => {
    this.audioEl.currentTime = 0.01;
    if (!this.state.playing) {
      this.setProgress();
    }
  };

  handleBonusPause = (bonusTrackId) => {
    this.setState({ playing: false, bonusShouldPause: false, bonusId: bonusTrackId }, () => {
      this.audioEl.pause();
      clearInterval(this.tm);
      clearInterval(this.dbTm);
    });
  };

  render() {
    const bonusTracks = this.state.bonusTracks.map((el, i) => (
      <BonusTrack
        shouldPause={this.state.bonusShouldPause}
        handlePause={this.handleBonusPause}
        bonusId={this.state.bonusId}
        key={i}
        data={el}
      />
    ));
    return (
      <div className="player-container">
        <nav onClick={() => this.props.history.goBack()}>
          <ImageFadeIn src={backArrow} alt="back" className="back-arrow" />
          {this.state.filter || this.state.dashboard
            ? 'BACK'
            : `${this.state.catTitle.toUpperCase()} JOURNEYS`}
        </nav>
        <div id="player" className="player">
          <audio preload="auto" id="track-audio" src={this.state.trackUrl} />
          <audio preload="auto" id="music-audio" src={this.state.musicUrl} />
          <h1 className="title">{this.state.trackTitle}</h1>
          <h3 className="length">{this.formatTime(this.state.length, 'length')}</h3>
          <p className="description">{this.state.trackDescription}</p>
          <div className="controls-container">
            <div className="controls">
              <div className="back-container">
                <ImageFadeIn
                  src={back}
                  alt="back"
                  className="back"
                  onClick={() => this.handleSeek('back')}
                />
                <span className="back-text">15 sec</span>
              </div>
              <div className="circle">
                <Circle
                  percent={this.state.percent}
                  strokeLinecap="butt"
                  strokeWidth="18"
                  trailWidth="18"
                  strokeColor="#FFFFFF"
                  trailColor="#34374a"
                />
                <ImageFadeIn
                  src={this.state.playing ? pause : play}
                  alt="play/pause"
                  onClick={() => this.playToggle()}
                  className="play"
                />
              </div>
              <div className="forward-container">
                <ImageFadeIn
                  src={forward}
                  alt="forward"
                  className="forward"
                  onClick={() => this.handleSeek('forward')}
                />
                <span className="forward-text">30 sec</span>
              </div>
            </div>
            <span className="current-time">
              {this.formatTime(this.audioEl.currentTime ? this.audioEl.currentTime : 0)}
            </span>
            <div onClick={() => this.handleRestart()} className="restart-container">
              <ImageFadeIn src={restart} alt="restart" className="restart" />
              restart
            </div>
          </div>
          <div className="audio-toggle">
            <div
              onClick={() => this.handleToggle('audio')}
              className={this.state.audio ? 'all-audio-container-selected' : 'all-audio-container'}
            >
              <ImageFadeIn className="all-audio" src={sound} alt="all audio" />
              <span className="text">All audio</span>
            </div>
            <div
              onClick={() => this.handleToggle('music')}
              className={
                this.state.music ? 'only-music-container-selected' : 'only-music-container'
              }
            >
              <ImageFadeIn className="only-music" src={music} alt="only music" />
              <span className="text">Music only</span>
            </div>
          </div>
          <div className={this.state.listened ? 'player-listened-show' : 'player-listened-hide'}>
            <ImageFadeIn src={check} alt="listened" className="check" />
            <div
              onClick={() => {
                this.setState({ listened: false, keyboard: true });
                this.setProgress();
              }}
              className="relisten"
            >
              <ImageFadeIn src={forward} alt="relisten" className="relisten-arrow" />
              <span className="relisten-text">relisten</span>
            </div>
            {this.state.next ? (
              hasAccess(this.props.userData.subscription_end_date) || this.state.nextTrackIsFree ? (
                <div
                  onClick={() => {
                    this.setState({
                      imageUrl: this.state.nextImageUrl,
                      trackId: this.state.nextTrackId,
                      trackUrl: this.state.nextTrackUrl,
                      musicUrl: this.state.nextMusicUrl,
                      trackTitle: this.state.nextTrackTitle,
                      trackDescription: this.state.nextTrackDescription,
                      listened: false,
                      keyboard: true,
                    });
                    this.setProgress();
                  }}
                  className="next"
                >
                  <ImageFadeIn
                    src={this.state.nextImageUrl}
                    alt="next-track"
                    className="next-track-image"
                  />
                  <div className="text">
                    <p>Up next</p>
                    <span className="track-name">{this.state.nextTrackTitle}</span>
                  </div>
                  <ImageFadeIn src={next} alt="next" className="next-arrow" />
                </div>
              ) : (
                <div
                  onClick={() => {
                    this.setState({ modal: true });
                    clearInterval(this.dbTm);
                  }}
                  className="next"
                >
                  <ImageFadeIn
                    src={this.state.nextImageUrl}
                    alt="next-track"
                    className="next-track-image"
                  />
                  <div className="text">
                    <p>Up next</p>
                    <span className="track-name">{this.state.nextTrackTitle}</span>
                  </div>
                  <ImageFadeIn src={next} alt="next" className="next-arrow" />
                </div>
              )
            ) : (
              <> </>
            )}
          </div>
        </div>
        <p className="bonus-title">{this.state.bonusTitle}</p>
        <div className="bonus-tracks">{bonusTracks}</div>

        <div className={this.state.modal ? 'dark-bg-settings visible' : 'dark-bg-settings'}>
          <SubscribeModal handleCancel={this.handleCancel} />
        </div>
      </div>
    );
  }
}

export default Player;
