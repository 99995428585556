import React, { Component } from 'react';
import axios from 'axios';
import { BounceLoader } from 'react-spinners';
import { css } from '@emotion/core';
import { withRouter } from 'react-router-dom';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

class AdminBlog extends Component {
  state = {
    blogTitle: '',
    blogUrl: '',
    blogDescription: '',
    selectedImageFile: null,
    loading: false,
  };

  handleChange(value, type) {
    if (type === 'title') {
      this.setState({ blogTitle: value });
    } else if (type === 'url') {
      this.setState({ blogUrl: value });
    } else if (type === 'description') {
      this.setState({ blogDescription: value });
    }
  }

  handleFile(file) {
    this.setState({ selectedImageFile: file });
  }

  handleSubmit() {
    if (
      !this.state.blogTitle ||
      !this.state.blogUrl ||
      !this.state.blogDescription ||
      !this.state.selectedImageFile
    ) {
      return window.alert('Please fill everything out');
    }

    const submitButton = document.getElementById('submit');
    submitButton.disabled = true;

    this.setState({ loading: true });

    const formData = new FormData();

    formData.append('image_file', this.state.selectedImageFile, this.state.selectedImageFile.name);
    formData.append('blog_title', this.state.blogTitle);
    formData.append('blog_url', this.state.blogUrl);
    formData.append('blog_description', this.state.blogDescription);

    axios
      .post('/api/admin/blog', formData)
      .then((res) => {
        if (res.status === 200) {
          window.alert('Blog post added successfully!');
          this.setState(
            {
              blogTitle: '',
              blogUrl: '',
              blogDescription: '',
              selectedImageFile: null,
              loading: false,
            },
            () => {
              submitButton.disabled = false;
              document.getElementById('image-file').value = null;
            },
          );
        } else {
          window.alert('unknown error');
          submitButton.disabled = false;
          this.setState({ loading: false });
        }
      })
      .catch((err) => this.props.history.push('/home'));
  }

  render() {
    return (
      <div className="admin-blog-container">
        <h3>Add Blog Post</h3>
        <span>Title: </span>
        <input
          onChange={(e) => this.handleChange(e.target.value, 'title')}
          placeholder="Title"
          type="text"
          value={this.state.blogTitle}
        />

        <span>Blog URL: </span>
        <input
          onChange={(e) => this.handleChange(e.target.value, 'url')}
          placeholder="URL"
          type="text"
          value={this.state.blogUrl}
        />

        <span>Description: </span>
        <input
          onChange={(e) => this.handleChange(e.target.value, 'description')}
          placeholder="Description"
          type="text"
          value={this.state.blogDescription}
        />

        <div className="upload">
          <span>Image file</span>
          <input id="image-file" onChange={(e) => this.handleFile(e.target.files[0])} type="file" />
        </div>

        <button onClick={() => this.handleSubmit()} id="submit" className="submit">
          Submit
        </button>
        {this.state.loading ? (
          <div className="loading-results-admin">
            <BounceLoader
              color={'#a89cd9'}
              css={override}
              sizeUnit={'px'}
              size={75}
              loading={this.state.loading}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  }
}

export default withRouter(AdminBlog);
